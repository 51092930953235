import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ILinkedInAdAccount } from 'src/app/core/models/linkedin/in-ad-account.model';
import { LINKEDIN_AD_ACCOUNT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class LinkedInAdAccountsEntityService extends EntityCollectionServiceBase<ILinkedInAdAccount> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(LINKEDIN_AD_ACCOUNT_ENTITY, serviceElementsFactory);
	}
}
