import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ILinkedInCampaignPlacements } from 'src/app/core/models/linkedin/in-campaign-placement.model';

@Injectable()
export class LinkedInCampaignPlacementDataService extends DefaultDataService<ILinkedInCampaignPlacements> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		campaignGroupId: string;
		campaignId: string;
	}): Observable<ILinkedInCampaignPlacements[]> {
		return this.appCoreServices.socialCampaignApiService.linkedin.campaignApi.getCampaignPlacements(
			params['integrationId'],
			params['adAccountId'],
			params['campaignGroupId'],
			params['campaignId'],
		);
	}
}
