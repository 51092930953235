import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { IPlacementInteraction } from 'src/app/presentation/features/ad/ad-editor/shared/placement-interaction/placement-interaction';

@Component({
	standalone: true,
	imports: [TextPipe, UIModule, CommonModule],
	selector: 'in-placement-footer',
	templateUrl: './in-placement-footer.component.html',
	styleUrls: ['./in-placement-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedInPlacementFooterComponent {
	@Input() public interactions?: IPlacementInteraction[] = [
		{ name: 'Like', icon: 'linkedin_like' },
		{ name: 'Comment', icon: 'linkedin_comment' },
		{ name: 'Repost', icon: 'linkedin_repost' },
		{ name: 'Send', icon: 'linkedin_send' },
	];
}
