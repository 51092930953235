import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LINKEDIN_AD_ACCOUNT_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ILinkedInAdAccount } from 'src/app/core/models/linkedin/in-ad-account.model';

@Injectable()
export class LinkedInAdAccountDataService extends DefaultDataService<ILinkedInAdAccount> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(LINKEDIN_AD_ACCOUNT_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
	}): Observable<ILinkedInAdAccount[]> {
		return this.appCoreServices.socialAccountApiService.getAdAccounts(
			params.integrationId,
		);
	}
}
