import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { LinkedInCampaignsApiService } from './services/in-campaigns-api.service';
import { LinkedInCampaignGroupsApiService } from './services/in-campaign-group-api.service';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignApiService extends BaseApiService {
	constructor(
		public http: HttpClient,
		public campaignApi: LinkedInCampaignsApiService,
		public campaignGroupApi: LinkedInCampaignGroupsApiService,
	) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/linkedin`);
	}
}
