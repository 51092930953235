import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, filter, tap } from 'rxjs';
import { IPlacementsSoundDictionary } from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';
import {
	ILinkedInPlacementMetaDataVM,
	ILinkedInSinglePlacementsMetaDataMap,
} from 'src/app/presentation/view-models/linkedin/placement/in-placement-metadata.vm';

export interface ILinkedInSinglePlacementsState {
	placementsMetaDataMap: ILinkedInSinglePlacementsMetaDataMap;
	placementsSound: IPlacementsSoundDictionary;
}

@Injectable()
export class LinkedInSinglePlacementsComponentStore extends ComponentStore<ILinkedInSinglePlacementsState> {
	constructor() {
		super({
			placementsMetaDataMap: {},
			placementsSound: {},
		});
	}

	public readonly setPlacementsMetaData = this.effect(
		(
			placementsMetaData$: Observable<ILinkedInSinglePlacementsMetaDataMap>,
		) =>
			placementsMetaData$.pipe(
				filter((placementsMetaData) => !!placementsMetaData),
				tap(
					(
						placementsMetaData: ILinkedInSinglePlacementsMetaDataMap,
					) => {
						this.updateMetaData(placementsMetaData);
					},
				),
			),
	);

	private readonly updateMetaData = this.updater(
		(
			state: ILinkedInSinglePlacementsState,
			placementsMetaData: ILinkedInSinglePlacementsMetaDataMap,
		) => ({
			...state,
			placementsMetaDataMap: placementsMetaData,
		}),
	);

	public readonly setPlacementsSound = this.effect(
		(placementsSound$: Observable<IPlacementsSoundDictionary>) =>
			placementsSound$.pipe(
				tap((placementsSound: IPlacementsSoundDictionary) => {
					this.updatePlacementsSoundState(placementsSound);
				}),
			),
	);

	public readonly setPlacementSound = this.effect(
		(param$: Observable<{ placementId: string; sound: SoundEnum }>) =>
			param$.pipe(
				tap((param: { placementId: string; sound: SoundEnum }) => {
					this.updatePlacementSoundState(param);
				}),
			),
	);

	private readonly updatePlacementSoundState = this.updater(
		(
			state: ILinkedInSinglePlacementsState,
			action: { placementId: string; sound: SoundEnum },
		) => ({
			...state,
			placementsSound: {
				...state.placementsSound,
				[action.placementId]: action.sound,
			},
		}),
	);

	private readonly updatePlacementsSoundState = this.updater(
		(
			state: ILinkedInSinglePlacementsState,
			action: IPlacementsSoundDictionary,
		) => ({
			...state,
			placementsSound: {
				...action,
			},
		}),
	);

	public loadPlacementsSound(): Observable<IPlacementsSoundDictionary> {
		return this.select((state) => state.placementsSound);
	}

	public loadPlacementMetaData(
		placementId: string,
	): Observable<ILinkedInPlacementMetaDataVM> {
		return this.select((state) => state.placementsMetaDataMap[placementId]);
	}
}
