import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdFormatEnum, IPublishLogItemVM } from '../../../../../view-models';
import { LinkedInSinglePlacementsComponent } from '../../placement/placements/in-single-placements.component';
import { LinkedInPlacementMetaDataMappersService } from '../../placement/placements/services/in-placement-meta.mappers';
import { ILinkedInSinglePlacementsMetaDataMap } from 'src/app/presentation/view-models/linkedin/placement/in-placement-metadata.vm';

@Component({
	selector: 'in-ad-placements-log',
	templateUrl: './in-ad-placements-log.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, LinkedInSinglePlacementsComponent],
})
export class LinkedInAdPlacementsLogComponent implements OnInit {
	@Input() public publishLogItem: IPublishLogItemVM | undefined;
	public adFormatEnum: typeof AdFormatEnum = AdFormatEnum;
	public placementsMetaData: ILinkedInSinglePlacementsMetaDataMap;

	constructor(
		private linkedInPlacementMetaDataMappersService: LinkedInPlacementMetaDataMappersService,
	) {}

	public ngOnInit(): void {
		this.placementsMetaData =
			this.linkedInPlacementMetaDataMappersService.fromAdLogtoPlacementsMetaData(
				this.publishLogItem,
			);
	}
}
