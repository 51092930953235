<ng-container>
    <div *ngIf="introductoryTextValue; else skeleton">
        <div class="introductory-text-value">
            {{introductoryTextValue | truncate: 100}}
        </div>
    </div>
    <ng-template #skeleton>
        <div class="introductory-text-default">
            <div *ngFor="let line of [].constructor(numberOfLines)" class="line"></div>
        </div>
    </ng-template>
</ng-container>
