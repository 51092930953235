import { NgModule } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { AppCoreServices } from './app-core-service';
import { AppRequestInterceptor } from './interceptors/app-request.interceptor';
import { Aith0RquestInterceptor as Auth0RquestInterceptor } from './interceptors/auth0-request.interceptor';
import { AuthService, provideAuth0 } from '@auth0/auth0-angular';
import {
	provideSentinelService,
	withLoggerConfig,
	withNewRelic,
} from '@bannerflow/sentinel';
import { NewRelicConfig } from './monitoring/new-relic/new-relic.config';
import { config } from './config/config';
import { ConfigService } from './config/config.service';
import { auth0Config } from './config/auth0.config';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject } from 'rxjs';
import { provideFeatureFlags } from '@bannerflow/feature-flags';

@NgModule({
	imports: [UIModule, CommonModule],
	providers: [
		AuthService,
		provideSentinelService(
			withNewRelic({
				...NewRelicConfig.init(),
			}),
			withLoggerConfig({
				level: config.logLevel,
				production: ConfigService.isProduction(),
				sandbox: ConfigService.isSandbox(),
				stage: ConfigService.getStage(),
			}),
		),
		provideAuth0(auth0Config),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppRequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Auth0RquestInterceptor,
			multi: true,
		},
		AppCoreServices,
		provideHttpClient(withInterceptorsFromDi()),
		provideNotificationService({
			apiUri: config.NOTS_URL,
			accessToken$: new BehaviorSubject<string | undefined>(undefined),
			signalR: {
				enabled: config.NSSignalR.enabled,
				url: config.NSSignalR.url,
			},
		}),
		provideFeatureFlags({
			enabled: true,
			appName: 'SCM',
			url: config.featureFlags.url,
			clientKey: config.featureFlags.clientKey,
		}),
	],
})
export class CoreModule {}
