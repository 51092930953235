import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdService } from 'src/app/features/ad/services/ad/ad.service';
import { AdMappersService } from 'src/app/features/ad/mappers/ad-mapper/ad.mapper';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { IAd, IAdContentDictionary } from 'src/app/core/models';
import { LinkedInAdMappersService } from '../../mappers/ad-mapper/in-ad.mapper';
import { ILinkedInAdVM } from 'src/app/presentation/view-models/linkedin/ad/in-ad.vm';
import {
	IAdContentTemplateVM,
	IAdListItemVM,
	IAssignedCreativeVM,
	IDetailedAdVM,
} from 'src/app/presentation/view-models';
import { LinkedInAdContentIdEnum } from 'src/app/presentation/features/integrations/linkedin/ad/enums/in-ad-content-id.enums';
import { AdValidationService } from 'src/app/features/ad/services/ad-validation/ad-validation.service';

@Injectable({
	providedIn: 'root',
})
export class LinkedInAdService extends AdService {
	public adContentId: typeof LinkedInAdContentIdEnum =
		LinkedInAdContentIdEnum;

	constructor(
		public appEntityServices: AppEntityServices,
		public adMapper: AdMappersService,
		public linkAdMapper: LinkedInAdMappersService,
		public adValidationService: AdValidationService,
	) {
		super(appEntityServices, adValidationService, adMapper, null);
	}

	public loadById(adId: string): Observable<ILinkedInAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(map((ad) => this.linkAdMapper.toAdVM(ad)));
	}

	public loadAdsListByAdGroupId(
		adGroupId: string,
	): Observable<IAdListItemVM[]> {
		const adIds$: Observable<string[]> =
			this.loadAdsIdsByAdGroupId(adGroupId);

		return adIds$.pipe(
			switchMap((ids) => {
				if (!ids.length) {
					return of([]);
				} else {
					return combineLatest(
						ids.map((adId) => this.loadAdListInfo(adId)),
					);
				}
			}),
		);
	}

	public loadAdListInfo(adId: string): Observable<IAdListItemVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(
			map((ad) => this.linkAdMapper.linkAdListMapper.toAdListVM(ad)),
		);
	}

	public addAd(fromViewModel: ILinkedInAdVM): Observable<IAd> {
		const ad: IAd = this.linkAdMapper.toAd(fromViewModel);
		return this.appEntityServices.adEntity.ad.add(ad);
	}

	public saveAd(adVM: ILinkedInAdVM): Observable<IAd> {
		const ad = this.linkAdMapper.toAd(adVM);
		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public addDefaultTargetUrlToContentAndValidate(
		ad: IDetailedAdVM,
		adCreatives: IAssignedCreativeVM[],
		contentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const destinationUrlTemplate = contentTemplates.find(
			(adContentTemplate) =>
				adContentTemplate.id === LinkedInAdContentIdEnum.destinationUrl,
		);
		const targetUrl = adCreatives?.[0]?.targetUrl;
		const canAssignDefaultTargetUrl =
			destinationUrlTemplate &&
			targetUrl &&
			ad.defaultContent[LinkedInAdContentIdEnum.destinationUrl] ===
				undefined;

		if (canAssignDefaultTargetUrl) {
			this.adValidationService.validateUrlForPlacements(
				ad.id,
				destinationUrlTemplate.placementsIds,
				ad.adFormatId,
				targetUrl,
				LinkedInAdContentIdEnum.destinationUrl,
			);

			return {
				...ad.defaultContent,
				[LinkedInAdContentIdEnum.destinationUrl]: targetUrl,
			};
		}

		return ad.defaultContent;
	}
}
