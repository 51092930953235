import { ConfigService } from './config.service';

export const auth0Config = {
	domain: ConfigService.config.IDP_URL,
	clientId: ConfigService.config.CLIENT_ID,
	authorizationParams: {
		redirect_uri: window.location.search.includes('branch=')
			? `${window.location.origin}/${window.location.search}`
			: `${window.location.origin}`,
		audience: 'https://bannerflow.com/resources/',
		scope:
			'openid ' +
			'profile ' +
			'offline_access ' +
			'studio ' +
			'bannerflow ' +
			'listservice ' +
			'socialcampaignservice ' +
			'socialaccountservice ' +
			'socialpublishservice',
	},
	httpInterceptor: {
		allowedList: [
			`${ConfigService.config.ACG_URL}*`,
			`${ConfigService.config.ACA_URL}*`,
			`${ConfigService.config.BM_URL}*`,
			`${ConfigService.config.CLV_URL}*`,
			`${ConfigService.config.SAPI_URL}*`,
			`${ConfigService.config.SAS_URL}*`,
			`${ConfigService.config.SCS_URL}*`,
			`${ConfigService.config.SPS_URL}*`,
			`${ConfigService.config.NOTS_URL}*`,
			`${ConfigService.config.STUDIO_URL}*`,
		],
	},
	useRefreshTokens: true,
};
