import { Injectable } from '@angular/core';
import { IAdAccount } from 'src/app/core/models/account/integration/ad-account.model';
import { ILinkedInAdAccountVM } from 'src/app/presentation/view-models/linkedin/account/in-ad-account.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInAdAccountMapperService {
	public toAdAccountVM(adAccount: IAdAccount): ILinkedInAdAccountVM {
		const adAccountVM: ILinkedInAdAccountVM = {
			id: adAccount.id,
			name: adAccount.name,
			integrationId: adAccount.integrationId,
		};

		return adAccountVM;
	}
}
