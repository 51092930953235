import { Injectable } from '@angular/core';
import { IAdStatus, IConnection } from 'src/app/core/models';
import {
	IAdGroupVM,
	IAdVM,
	ILocalizationVM,
	IPublishSummaryAdVM,
	IPublishSummaryChildrenVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class PublishSummaryMappersService {
	public mapAdToPublishSummaryAd(
		ads: IAdVM[],
		adGroups: IAdGroupVM[],
		languages: ILocalizationVM[],
		adStatuses: IAdStatus[],
		adGroupConnections: IConnection[],
	): IPublishSummaryAdVM[] {
		const languageDictionary = Object.assign(
			{},
			...languages.map((l) => ({ [l.id]: l })),
		);

		const selectedAds: IPublishSummaryAdVM[] = ads.map((ad) => ({
			...ad,
			adFormatId: ad.adFormatId,
			language: languageDictionary[ad.languageId],
			adGroup: adGroups.find((adgroup) => adgroup.id === ad.adGroupId),
			connection: this.getConnection(ad.adGroupId, adGroupConnections),
			children: this.mapSummaryChildren(ad, adGroups, languageDictionary),
			status: adStatuses.find((status) => status?.id === ad.id),
		}));

		return selectedAds;
	}

	private mapSummaryChildren(
		ad: IAdVM,
		adGroups: IAdGroupVM[],
		languageDictionary: any,
	): IPublishSummaryChildrenVM[] {
		return ad.children.map((child) => ({
			adId: ad.id,
			networkId: adGroups.find((adGroup) => adGroup.id === ad.adGroupId)
				.networkId,
			placementId: child.placementId,
			mediaType: child.mediaType,
			adFormatId: child.adFormatId,
			language: languageDictionary[ad.languageId],
			isCustomized: child.isCustomized,
		}));
	}

	private getConnection(
		adGroupId: string,
		adGroupConnections: IConnection[],
	): string {
		const connection = adGroupConnections.find(
			({ id }) => id === adGroupId,
		);

		const adAccount = this.getAdAccount(adGroupId, adGroupConnections);
		const adSetName = this.getAdSetName(adGroupId, adGroupConnections);

		if (connection) {
			const campaignAndObjective = `${connection.campaignName}, (${connection.objective})`;
			if (!adSetName) {
				return `${adAccount} - ${campaignAndObjective}`;
			} else {
				return `${adAccount} - ${adSetName} - ${campaignAndObjective}`;
			}
		}
	}

	private getAdAccount(
		adGroupId: string,
		adGroupConnections: IConnection[],
	): string {
		return adGroupConnections.find(({ id }) => id === adGroupId)
			?.adAccountName;
	}

	private getAdSetName(
		adGroupId: string,
		adGroupConnections: IConnection[],
	): string {
		const connection = adGroupConnections.find(
			({ id }) => id === adGroupId,
		);

		return connection?.adSetName || connection?.adGroupName;
	}
}
