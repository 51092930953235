import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ICta } from 'src/app/core/models/shared/cta.model';
import { AppEntityServices } from 'src/app/entities/app-entity-service';

@Injectable({
	providedIn: 'root',
})
export class FacebookCtaService {
	constructor(public appEntityServices: AppEntityServices) {}

	public getCta(): void {
		this.appEntityServices.facebookEntity.cta.getAll();
	}

	public loadAllCtas(): Observable<ICta[]> {
		const ctas$: Observable<ICta[]> =
			this.appEntityServices.facebookEntity.cta.entities$;

		return ctas$.pipe(
			filter((ctas) => !!ctas),
			map((ctas) => ctas),
		);
	}
}
