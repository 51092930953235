import { Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../../shared/enums';
import { FacebookPlacementHeaderComponent } from '../../../../shared/placement-header/fb-placement-header.component';
import { UIModule } from '@bannerflow/ui';
import { ReelsIconsComponent } from '../../../shared/reels-icons/reels-icons.component';
import { CreativeContentComponent } from '../../../../../../../../elements/creative-content/creative-content.component';
import { CommonModule } from '@angular/common';
import { PrimaryTextComponent } from '../../../../shared/primary-text/primary-text.component';
import { CtaButtonComponent } from '../../../../shared/cta-button/cta-button.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		ReelsIconsComponent,
		UIModule,
		FacebookPlacementHeaderComponent,
		PrimaryTextComponent,
		CtaButtonComponent,
	],
	selector: 'facebook-instagram-reels-placement-core',
	templateUrl: './fb-instagram-reels-placement-core.component.html',
	styleUrls: ['./fb-instagram-reels-placement-core.component.scss'],
})
export class FacebookInstagramReelsPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookInstagramReels,
		);
	}
}
