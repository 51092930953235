import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStudioCreativeSet } from 'src/app/core/models';
import { CREATIVE_SET_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class CreativeSetDataService extends DefaultDataService<IStudioCreativeSet> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(CREATIVE_SET_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		creativeSetsId: string[];
	}): Observable<IStudioCreativeSet[]> {
		return this.appCoreServices.socialCampaignApiService.creativeSet.getCreativeSetsByIds(
			params['creativeSetsId'],
		);
	}
}
