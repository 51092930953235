import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IAdFormat } from 'src/app/core/models';
import { LINKEDIN_ADFORMAT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class LinkedInAdFormatsEntityService extends EntityCollectionServiceBase<IAdFormat> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(LINKEDIN_ADFORMAT_ENTITY, serviceElementsFactory);
	}
}
