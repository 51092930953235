import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'preview-primary-texts',
	templateUrl: './preview-primary-texts.component.html',
	styleUrls: ['./preview-primary-texts.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, UIModule],
})
export class PreviewPrimaryTextsComponent implements OnChanges {
	@Input() public primaryTexts: string[];
	public firstPrimaryText: string;
	public number: number;
	public finalPrimaryTexts: string[];

	public ngOnChanges(): void {
		if (this.primaryTexts && this.primaryTexts.length > 0) {
			this.firstPrimaryText = this.primaryTexts[0];
			this.finalPrimaryTexts = this.removeDuplicatesAndFirst(
				this.primaryTexts,
				this.firstPrimaryText,
			);
			this.number = this.finalPrimaryTexts.length;
		}
	}

	public removeDuplicatesAndFirst(
		listOfValues: string[],
		firstValue: string,
	): string[] {
		const withoutDuplicates = listOfValues.filter(
			(value, index) => listOfValues.indexOf(value) === index,
		);
		const finalValues = withoutDuplicates.filter(
			(num) => num !== firstValue,
		);
		return finalValues;
	}
}
