<ng-container *ngIf="brand$ | async as brand">
    <div class="header-container">
        <div class="brand-container">
            <div class="brand-logo">
                <brand-logo
                    [brand]="brand"
                    [isSmall]="false"
                    [isSquare]="true"></brand-logo>
            </div>
            <div class="brand-content">
                <div class="brand-content-text">
                    <div class="brand-content-text-name">{{ brand?.name }}</div>
                    <div class="brand-content-text-promoted">
                        <ng-container *ngIf="isSponsored; else showPromoted">
                            {{ "PlacementHeaderComponent.SPONSORED" | text }}
                        </ng-container>
                        <ng-template #showPromoted>
                            {{ "PlacementHeaderComponent.PROMOTED" | text }}
                        </ng-template>
                    </div>
                    <div *ngIf="withFollowers" class="brand-content-text-promoted">
                        {{"PlacementHeaderComponent.FOLLOWERS" | text}}
                    </div>
                </div>
                <div class="brand-content-icons">
                    <ng-container *ngIf="withInfo; else showMore">
                        <ui-svg-icon icon="linkedin_info"></ui-svg-icon>
                    </ng-container>
                    <ng-template #showMore>
                        <ui-svg-icon icon="tiktok_more"></ui-svg-icon>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
