import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ILinkedInCampaign } from 'src/app/core/models/linkedin/in-campaign.model';
import { ILinkedInCampaignPlacements } from 'src/app/core/models/linkedin/in-campaign-placement.model';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignsApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/linkedin`);
	}

	/**
	 * Get campaigns
	 * @param integrationId
	 * @param adAccountId
	 * @param campaignGroupId
	 */
	public getCampaigns(
		integrationId: string,
		adAccountId: string,
		campaignGroupId: string,
	): Observable<ILinkedInCampaign[]> {
		const endpoint = `integrations/${integrationId}/ad-accounts/${adAccountId}/campaign-groups/${campaignGroupId}/campaigns`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<ILinkedInCampaign[]>(url).pipe(
			map((campaigns) =>
				campaigns.map((campaign) => ({
					...campaign,
					integrationId,
					adAccountId,
					campaignGroupId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Gets campaign placements
	 * @param integrationId
	 * @param adAccountId
	 * @param campaignGroupId
	 * @param campaignId
	 */
	public getCampaignPlacements(
		integrationId: string,
		adAccountId: string,
		campaignGroupId: string,
		campaignId: string,
	): Observable<ILinkedInCampaignPlacements[]> {
		const url = `${this.baseUrl}/integrations/${integrationId}/ad-accounts/${adAccountId}/campaign-groups/${campaignGroupId}/campaigns/${campaignId}/placements`;

		return this.http.get<string[]>(url).pipe(
			map((placements) => [
				{
					placementsIds: placements,
					id: campaignId,
				},
			]),
			catchError(ApiService.handleError),
		);
	}
}
