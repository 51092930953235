<div class="footer">
    <div class="footer-reactions">
        <div class="reactions">
            <ui-svg-icon [icon]="'linkedin-reactions-colored'"></ui-svg-icon>
            {{"PlacementFooterComponent.NumberOfReactions" | text}}
        </div>
        <div class="comments-reposts">
            {{"PlacementFooterComponent.Comments" | text}} &#183;
            {{"PlacementFooterComponent.Reposts" | text}}
        </div>
    </div>
    <div class="footer-line"></div>
    <div
        class="footer-interactions"
        [ngClass]="{'footer-interactions-center': interactions.length===1}">
        <div class="footer-interaction" *ngFor="let interaction of interactions">
            <ui-svg-icon [icon]="interaction.icon"></ui-svg-icon>
        </div>
    </div>
</div>
