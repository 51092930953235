import { Component, Input, OnInit } from '@angular/core';
import { AdFormatEnum } from 'src/app/presentation/view-models';
import * as Placements from './in-placements-components.const';
import { PlacementsWrapperComponent } from '../../../elements/placements-wrapper/placements-wrapper.component';

@Component({
	selector: 'in-placements-wrapper',
	templateUrl: './in-placements-wrapper.component.html',
	standalone: true,
	imports: [PlacementsWrapperComponent],
})
export class LinkedInPlacementsWrapperComponent implements OnInit {
	@Input() public placementId: string;
	@Input() public adFormatId: string;

	public placements: any;

	public ngOnInit(): void {
		switch (this.adFormatId) {
			case AdFormatEnum.singleImage:
			case AdFormatEnum.singleVideo:
				this.placements = Placements.SinglePlacements;
				break;
			default:
				break;
		}
	}
}
