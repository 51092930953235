import { Injectable, Injector } from '@angular/core';
import { LinkedInAccountFeatureService } from './account/in-account-feature.service';
import { AdFormatsService } from './ad-format/services/ad-formats.service';
import { LinkedInAdService } from './ad/services/ad/in-ad.service';
import { LinkedInPlacementService } from './placement/services/in-placement.service';

@Injectable()
export class LinkedInFeatureService {
	public account: LinkedInAccountFeatureService;
	public adformat: AdFormatsService;
	public ad: LinkedInAdService;
	public placement: LinkedInPlacementService;

	constructor(private injector: Injector) {
		this.account = this.injector.get(LinkedInAccountFeatureService);
		this.adformat = this.injector.get(AdFormatsService);
		this.ad = this.injector.get(LinkedInAdService);
		this.placement = this.injector.get(LinkedInPlacementService);
	}
}
