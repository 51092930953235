import { Injectable, Injector } from '@angular/core';
import { LinkedInAdAccountsEntityService } from './ad-account/in-ad-accounts-entity.service';
import { LinkedInCampaignEntityService } from './campaign/in-campaign-entity.service';
import { LinkedInCampaignGroupsEntityService } from './campaign-group/in-campaign-group-entity.service';
import { LinkedInAdFormatsEntityService } from './ad-format/in-ad-formats-entity.service';
import { LinkedInCampaignPlacementsEntityService } from './campaign-placement/in-campaign-placement-entity.service';
import { LinkedInSingleImagePlacementsEntityService } from './single-image-placement/in-single-image-placements-entity.service';
import { LinkedInSingleVideoPlacementsEntityService } from './single-video-placement/in-single-video-placements-entity.service';
import { LinkedInCtaEntityService } from './cta/in-cta-entity.service';

@Injectable()
export class LinkedInEntityServices {
	public adAccount: LinkedInAdAccountsEntityService;
	public campaign: LinkedInCampaignEntityService;
	public campaignGroup: LinkedInCampaignGroupsEntityService;
	public adFormat: LinkedInAdFormatsEntityService;
	public campaignPlacement: LinkedInCampaignPlacementsEntityService;
	public singleImagePlacement: LinkedInSingleImagePlacementsEntityService;
	public singleVideoPlacement: LinkedInSingleVideoPlacementsEntityService;
	public cta: LinkedInCtaEntityService;

	constructor(private injector: Injector) {
		this.adAccount = this.injector.get(LinkedInAdAccountsEntityService);
		this.campaign = this.injector.get(LinkedInCampaignEntityService);
		this.campaignGroup = this.injector.get(
			LinkedInCampaignGroupsEntityService,
		);
		this.adFormat = this.injector.get(LinkedInAdFormatsEntityService);
		this.campaignPlacement = this.injector.get(
			LinkedInCampaignPlacementsEntityService,
		);
		this.adFormat = this.injector.get(LinkedInAdFormatsEntityService);
		this.singleImagePlacement = this.injector.get(
			LinkedInSingleImagePlacementsEntityService,
		);
		this.singleVideoPlacement = this.injector.get(
			LinkedInSingleVideoPlacementsEntityService,
		);
		this.cta = this.injector.get(LinkedInCtaEntityService);
	}
}
