import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_STATUS_ENTITY } from 'src/app/state/app.state';
import { IAdStatus } from 'src/app/core/models';
import { SocialPublishApiService } from 'src/app/core/services/social-publish-service/social-publish-api.service';

@Injectable()
export class AdStatusDataService extends DefaultDataService<IAdStatus> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private publishServiceApi: SocialPublishApiService,
	) {
		super(AD_STATUS_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		adId: string;
		integrationId: string;
	}): Observable<IAdStatus[]> {
		return this.publishServiceApi.getAdStatus(
			params.adId,
			params.integrationId,
		);
	}
}
