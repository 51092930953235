import { Injectable } from '@angular/core';
import { PublishLogMappersService } from '../../../publish/mappers/publish-log.mapper';
import {
	IPublishLogItemVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { IAdContentDictionary } from 'src/app/core/models';
import { LinkedInAdContentIdEnum } from 'src/app/presentation/features/integrations/linkedin/ad/enums/in-ad-content-id.enums';

@Injectable({
	providedIn: 'root',
})
export class LinkedInPublishLogMappersService extends PublishLogMappersService {
	constructor() {
		super();
	}

	public toPublishLogItemViewModel(
		publishLog: IPublishLogEntry,
	): IPublishLogItemVM {
		if (!publishLog?.ad) {
			return this.toPublishLogItemViewModelWithEmptyAd(publishLog);
		}

		return {
			id: publishLog.id,
			adId: publishLog.adId,
			networkId: NetworkEnum.linkedin,
			adFormat: this.toAdFormatVM(publishLog.ad?.format),
			adName: publishLog.ad?.name,
			date: publishLog.publishingStarted,
			languageId: publishLog.ad?.language,
			publishedBy: publishLog.publishedBy,
			status: publishLog.status,
			statusMessage: publishLog.statusMessage,
			creativeAssigned: publishLog.ad?.assignedCreativeSet,
			texts: this.mapText(publishLog?.ad?.placements[0]?.content),
			url: this.mapUrl(publishLog?.ad?.placements[0]?.content),
			placements: publishLog.ad
				? this.toPlacementsVM(publishLog.ad?.placements)
				: [],
		};
	}

	private mapText(content: IAdContentDictionary): string[] | undefined {
		if (!content) {
			return undefined;
		}

		const text = content[LinkedInAdContentIdEnum.introductoryText];

		return text ? [text] : undefined;
	}

	private mapUrl(content: IAdContentDictionary): string | undefined {
		if (!content) {
			return undefined;
		}

		if (content[LinkedInAdContentIdEnum.destinationUrl]) {
			return content[LinkedInAdContentIdEnum.destinationUrl];
		}
	}
}
