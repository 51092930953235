import { Injectable } from '@angular/core';
import { IPlacementTemplate } from 'src/app/core/models';
import { IAdContentTemplate } from 'src/app/core/models/placement/ad-content-template.model';
import { IPlacementOptions } from 'src/app/core/models/placement/placement-options.model';
import {
	IAdContentTemplateVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { ILinkedInPlacementVM } from 'src/app/presentation/view-models/linkedin/placement/in-placement.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInPlacementMappersService {
	public mapAdContentTemplateToVM(
		adContentTemplate: IAdContentTemplate,
	): IAdContentTemplateVM {
		return {
			...adContentTemplate,
		};
	}

	public mapPlacements(
		placements: IPlacementTemplate[] | IPlacementBaseVM[],
	): ILinkedInPlacementVM[] {
		return placements.map((placement) => this.mapPlacement(placement));
	}

	public mapPlacement(placement: IPlacementTemplate): ILinkedInPlacementVM {
		return {
			...placement,
			adContentTemplates: placement.adContentTemplates.map(
				(adContentTemplate) =>
					this.mapAdContentTemplateToVM(adContentTemplate),
			),
		};
	}

	public mapPlacementsWithRenderingOptions(
		placements: IPlacementOptions[],
	): IPlacementOptionsVM[] {
		return placements.map((placement) =>
			this.mapPlacementWithRenderingOptions(placement),
		);
	}

	public mapPlacementWithRenderingOptions(
		placement: IPlacementOptions,
	): IPlacementOptionsVM {
		return {
			...placement,
		};
	}

	public mapCombinedContentTemplates(
		placements: ILinkedInPlacementVM[],
	): IAdContentTemplateVM[] {
		const contentTemplates: IAdContentTemplateVM[] = [];

		placements.forEach((placement) => {
			const { adContentTemplates } = placement;

			adContentTemplates.forEach((template) => {
				const existingTemplate = contentTemplates.find(
					({ id }) => id === template.id,
				);

				if (!existingTemplate) {
					const newTemplate = {
						...template,
						placementsIds: [placement.id],
					};

					contentTemplates.push(newTemplate);
				} else {
					existingTemplate.placementsIds?.push(placement.id);
				}
			});
		});

		return contentTemplates;
	}
}
