<ng-container>
    <div class="placement-title" *ngIf="placement$ | async as placement">
        <div [ngSwitch]="placement.id" class="category-icon">
            <ui-svg-icon *ngSwitchCase="placementEnum.LinkedInLinkedIn" icon="linkedin" />
            <ui-svg-icon
                *ngSwitchCase="placementEnum.LinkedInAudienceNetwork"
                icon="linkedin" />
        </div>
        <div>{{ placement.name }}</div>
    </div>
</ng-container>
