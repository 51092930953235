<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement" data-cy="linkedin-linkedin-placement">
        <in-brand-header [withFollowers]="true" />
        <in-introductory-text
            [introductoryTextValue]="placementMetaData?.introductoryText" />
        <div class="placement-creative">
            <creative-content
                *ngIf="placementMetaData.creative?.id || placementMetaData?.creativeUrl"
                [sound]="placementSound$ | async"
                [creativeSetId]="placementMetaData?.creative?.setId"
                [creativeId]="placementMetaData?.creative?.id"
                [creativeUrl]="placementMetaData.creativeUrl"
                [selectedMediaType]="placementMetaData.creative.renderingOption" />
        </div>
        <div class="headline-cta-bar">
            <in-headline [headlineValue]="placementMetaData?.headline" />
            <in-cta-button [ctaValue]="placementMetaData?.callToAction" />
        </div>
        <in-placement-footer />
    </div>
</ng-container>
