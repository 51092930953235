import { Injectable } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';

import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { LinkedInCampaignPlacementBaseService } from './in-campaign-placement-base.service';
import { ILinkedInCampaignPlacements } from 'src/app/core/models/linkedin/in-campaign-placement.model';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignPlacementService extends LinkedInCampaignPlacementBaseService {
	constructor(public appEntityServices: AppEntityServices) {
		super(appEntityServices);
	}

	public loadCampaignPlacements(campaignId: string): Observable<string[]> {
		const campaignPlacements$: Observable<ILinkedInCampaignPlacements[]> =
			this.appEntityServices.linkedInEntity.campaignPlacement.entities$;
		return campaignPlacements$.pipe(
			map((campaignPlacements) =>
				campaignPlacements.find(
					(campaignPlacement) => campaignPlacement.id === campaignId,
				),
			),
			filter((campaignPlacements) => !!campaignPlacements),
			map((campaignPlacements) => campaignPlacements.placementsIds),
		);
	}

	public loadCampaignPlacementsByAdGroupId(
		adGroupId: string,
	): Observable<string[]> {
		const adGroupConnection$ =
			this.appEntityServices.adEntity.adGroupConnection.entities$.pipe(
				filter((connections) => !!connections),
				map((connections) =>
					connections.find(
						(connection) => connection.id === adGroupId,
					),
				),
				map((connection) => connection),
			);

		return adGroupConnection$.pipe(
			switchMap((connection) =>
				this.loadCampaignPlacements(connection?.campaignId),
			),
		);
	}
}
