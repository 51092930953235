import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { FbMenuDotsIconComponent } from '../../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';
import { StoriesReelsBrandComponent } from '../stories-reels-brand/stories-reels-brand.component';

@Component({
	selector: 'fb-stories-header',
	templateUrl: './stories-header.component.html',
	styleUrls: ['./stories-header.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		FbMenuDotsIconComponent,
		StoriesReelsBrandComponent,
	],
})
export class FacebookStoriesHeaderComponent {
	@Input({ required: true }) public currentIndex: number;
	@Input({ required: true }) public cardsLength: number;

	public brand$: Observable<IBrandVM>;

	constructor(private appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
