import { EntityMetadata, EntityMetadataMap } from '@ngrx/data';

const defaultEntityMetadata: Partial<EntityMetadata> = {
	entityDispatcherOptions: {
		optimisticDelete: true,
		optimisticUpdate: true,
		optimisticAdd: false,
	},
};

export const entityMetadata: EntityMetadataMap = {
	Campaign: { ...defaultEntityMetadata },
	User: { ...defaultEntityMetadata },
	Localization: { ...defaultEntityMetadata },
	TimeZone: { ...defaultEntityMetadata },
	PublishLog: { ...defaultEntityMetadata },
	FacebookAdFormat: { ...defaultEntityMetadata },
	FacebookAdAccount: { ...defaultEntityMetadata },
	FacebookAdSet: { ...defaultEntityMetadata },
	FacebookAdSetPlacement: { ...defaultEntityMetadata },
	FacebookCampaign: { ...defaultEntityMetadata },
	FacebookPage: { ...defaultEntityMetadata },
	FacebookInstagramAccount: { ...defaultEntityMetadata },
	FacebookSinglePlacement: { ...defaultEntityMetadata },
	FacebookCarouselPlacement: { ...defaultEntityMetadata },
	FacebookCta: { ...defaultEntityMetadata },
	AdGroup: { ...defaultEntityMetadata },
	AdGroupConnection: { ...defaultEntityMetadata },
	Ad: { ...defaultEntityMetadata },
	AdLog: { ...defaultEntityMetadata },
	CreativeSetFolder: { ...defaultEntityMetadata },
	AdContentValidation: { ...defaultEntityMetadata },
	AdContentCarouselValidation: { ...defaultEntityMetadata },
	CreativeSet: { ...defaultEntityMetadata },
	AdStatus: { ...defaultEntityMetadata },
	AdSchedule: { ...defaultEntityMetadata },
	AdPlacementCustomization: { ...defaultEntityMetadata },
	AdPlacementTemplate: { ...defaultEntityMetadata },
	AdCustomizedValidation: { ...defaultEntityMetadata },
	Integration: { ...defaultEntityMetadata },
	IntegrationConnectionStatus: { ...defaultEntityMetadata },
	Network: { ...defaultEntityMetadata },
	TiktokAdFormat: { ...defaultEntityMetadata },
	TiktokAdAccount: { ...defaultEntityMetadata },
	TiktokAdGroup: { ...defaultEntityMetadata },
	TiktokAdGroupPlacement: { ...defaultEntityMetadata },
	TiktokCampaign: { ...defaultEntityMetadata },
	TiktokIdentity: { ...defaultEntityMetadata },
	TiktokSingleImagePlacement: { ...defaultEntityMetadata },
	TiktokSingleVideoPlacement: { ...defaultEntityMetadata },
	TiktokCta: { ...defaultEntityMetadata },
	LinkedInAdAccount: { ...defaultEntityMetadata },
	LinkedInCampaignGroup: { ...defaultEntityMetadata },
	LinkedInCampaign: { ...defaultEntityMetadata },
	LinkedInAdFormat: { ...defaultEntityMetadata },
	LinkedInCampaignPlacement: { ...defaultEntityMetadata },
	LinkedInSingleImagePlacement: { ...defaultEntityMetadata },
	LinkedInSingleVideoPlacement: { ...defaultEntityMetadata },
	LinkedInCta: { ...defaultEntityMetadata },
};
