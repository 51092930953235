export enum FacebookSupportedPlacements {
	// Feeds
	FacebookGroupsFeed = 'facebook-groups-feed',
	FacebookInstagramExplore = 'facebook-instagram-explore',
	FacebookNewsFeed = 'facebook-news-feed',
	FacebookMarketPlace = 'facebook-marketplace',
	FacebookMessengerInbox = 'facebook-messenger-inbox',
	FacebookRightColumn = 'facebook-right-column',
	FacebookVideoFeed = 'facebook-video-feed',

	// Stories & Reels
	FacebookInstagramStories = 'facebook-instagram-stories',
	FacebookMessengerStories = 'facebook-messenger-stories',
	FacebookStories = 'facebook-stories',
	FacebookInstagramReels = 'facebook-instagram-reels',
	FacebookReels = 'facebook-reels',
	FacebookAdsOnReels = 'facebook-ads-on-facebook-reels',

	// Other
	FacebookAudienceNetworkNative = 'facebook-audience-network-native',
	FacebookInstagramFeed = 'facebook-instagram-feed',
	FacebookInstagramProfileFeed = 'facebook-instagram-profile-feed',

	FacebookInStreamVideos = 'facebook-in-stream-videos',
	FacebookSearchResults = 'facebook-search-results',
	FacebookInstagramExploreHome = 'facebook-instagram-explore-home',
}

export const FacebookSupportedPlacementIds: string[] = Object.keys(
	FacebookSupportedPlacements,
).map((key) => FacebookSupportedPlacements[key]);

export const FacebookSupportedPlacementIdsOrder = {
	// Feeds
	'facebook-groups-feed': 0,
	'facebook-instagram-explore': 1,
	'facebook-news-feed': 2,
	'facebook-marketplace': 3,
	'facebook-messenger-inbox': 4,
	'facebook-right-column': 5,
	'facebook-video-feed': 6,

	// Stories & Reels
	'facebook-instagram-stories': 7,
	'facebook-messenger-stories': 8,
	'facebook-stories': 9,
	'facebook-instagram-reels': 10,
	'facebook-reels': 11,
	'facebook-ads-on-facebook-reels': 12,

	// Other
	'facebook-audience-network-native': 13,
	'facebook-instagram-feed': 14,
	'facebook-instagram-profile-feed': 15,
	'facebook-in-stream-videos': 16,
	'facebook-search-results': 17,
	'facebook-instagram-explore-home': 18,
};
