import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ILinkedInCampaignGroup } from 'src/app/core/models/linkedin/in-campaign-group.model';
import { LINKEDIN_CAMPAIGN_GROUP_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class LinkedInCampaignGroupsEntityService extends EntityCollectionServiceBase<ILinkedInCampaignGroup> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(LINKEDIN_CAMPAIGN_GROUP_ENTITY, serviceElementsFactory);
	}
}
