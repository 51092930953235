import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { TiktokAdGroupApiService } from './services/tt-ad-group-api.service';
import { TiktokIdentityApiService } from './services/tt-identity-api.service';
import { TiktokCampaignsApiService } from './services/tt-campaign-api.service';

@Injectable({
	providedIn: 'root',
})
export class TiktokCampaignApiService extends BaseApiService {
	constructor(
		public http: HttpClient,
		public adGroupApi: TiktokAdGroupApiService,
		public campaignApi: TiktokCampaignsApiService,
		public identityApi: TiktokIdentityApiService,
	) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/tiktok`);
	}
}
