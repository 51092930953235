import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ITiktokAdAccount } from 'src/app/core/models';
import { TIKTOK_AD_ACCOUNT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class TiktokAdAccountDataService extends DefaultDataService<ITiktokAdAccount> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_AD_ACCOUNT_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
	}): Observable<ITiktokAdAccount[]> {
		return this.appCoreServices.socialAccountApiService.getAdAccounts(
			params.integrationId,
		);
	}
}
