import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LINKEDIN_CAMPAIGN_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ILinkedInCampaign } from 'src/app/core/models/linkedin/in-campaign.model';

@Injectable()
export class LinkedInCampaignDataService extends DefaultDataService<ILinkedInCampaign> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(LINKEDIN_CAMPAIGN_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		campaignGroupId: string;
	}): Observable<ILinkedInCampaign[]> {
		return this.appCoreServices.socialCampaignApiService.linkedin.campaignApi.getCampaigns(
			params['integrationId'],
			params['adAccountId'],
			params['campaignGroupId'],
		);
	}
}
