import { Directive, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LinkedInSupportedPlacements } from '../../enums/in-supported-placement.enum';
import { ILinkedInPlacementMetaDataVM } from 'src/app/presentation/view-models/linkedin/placement/in-placement-metadata.vm';
import { LinkedInSinglePlacementsComponentStore } from '../in-single-placements.component.store';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Directive()
export abstract class LinkedInPlacementBaseDirective implements OnInit {
	public placementId: LinkedInSupportedPlacements;
	public placementMetaData$: Observable<ILinkedInPlacementMetaDataVM>;
	public placementSound$: Observable<SoundEnum>;

	constructor(
		public linkedInSinglePlacementsComponentStore: LinkedInSinglePlacementsComponentStore,
		placementId: LinkedInSupportedPlacements,
	) {
		this.placementId = placementId;
	}

	public ngOnInit(): void {
		this.placementSound$ = this.linkedInSinglePlacementsComponentStore
			.loadPlacementsSound()
			.pipe(map((placementsSound) => placementsSound[this.placementId]));
		this.placementMetaData$ =
			this.linkedInSinglePlacementsComponentStore.loadPlacementMetaData(
				this.placementId,
			);
	}
}
