export enum LinkedInSupportedPlacements {
	LinkedInLinkedIn = 'linkedin-linkedin',
	LinkedInAudienceNetwork = 'linkedin-audience-network',
}

export const SupportedPlacementIds: string[] = Object.keys(
	LinkedInSupportedPlacements,
).map((key) => LinkedInSupportedPlacements[key]);

export const SupportedPlacementIdsOrder = {
	'linkedin-linkedin': 0,
	'linkedin-audience-network': 1,
};
