<div
    class="cta-button call-to-action"
    [ngClass]="placementId"
    [class.expanded]="isExpanded"
    [class.collapsed]="!isExpanded">
    <div class="cta-container" *ngIf="(ctaName$ | async) || ctaValue; else ctaDefault">
        <div>{{ (ctaName$ | async) || ctaValue }}</div>
        <ui-svg-icon
            icon="arrow-right"
            *ngIf="placementId === supportedPlacementId.FacebookInstagramExplore || placementId === supportedPlacementId.FacebookInstagramFeed || placementId === supportedPlacementId.FacebookInstagramProfileFeed || placementId === supportedPlacementId.FacebookInstagramExploreHome" />
    </div>

    <ng-template #ctaDefault>
        <div class="default"></div>
    </ng-template>
</div>
