import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable, catchError, map } from 'rxjs';
import { IAdSchedule } from 'src/app/core/models/ad/ad-schedule/ad-schedule';
import { ApiService } from '../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class AdScheduleApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SPS_URL}/api/ads`);
	}

	/**
	 * Put ad schedule
	 * @param adSchedule
	 */
	public putSchedule(adSchedule: IAdSchedule): Observable<IAdSchedule> {
		const { id, ...payload } = adSchedule;
		const endpoint = `${id}/schedule`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.put<IAdSchedule>(url, payload).pipe(
			map(() => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { integrationId: _, ...schedule } = adSchedule;
				return schedule;
			}),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get ad schedule
	 * @param adId
	 */
	public getSchedule(adId: string): Observable<IAdSchedule> {
		const endpoint = `${adId}/schedule`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<IAdSchedule>(url).pipe(
			map((response) => (response ? { id: adId, ...response } : null)),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Delete schedule
	 * @param adId
	 */
	public delete(adId: string): Observable<string> {
		const endpoint = `${adId}/schedule`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.delete<string>(url).pipe(
			map((response) => response),
			catchError(ApiService.handleError),
		);
	}
}
