import { Injectable } from '@angular/core';
import {
	IAdContentTemplateVM,
	IAdGroupVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { Observable, filter, map, of } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IPlacementOptions } from 'src/app/core/models';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';
import { LinkedInSingleImagePlacementService } from './single-image-placement/in-single-image-placement.service';
import { LinkedInPlacementMappersService } from '../mappers/in-placement.mappers';
import { ILinkedInPlacementVM } from 'src/app/presentation/view-models/linkedin/placement/in-placement.vm';
import { ILinkedInAdVM } from 'src/app/presentation/view-models/linkedin/ad/in-ad.vm';
import { LinkedInSingleVideoPlacementService } from './single-video-placement/in-single-video-placement.service';

@Injectable({
	providedIn: 'root',
})
export class LinkedInPlacementService {
	constructor(
		public singleImage: LinkedInSingleImagePlacementService,
		public singleVideo: LinkedInSingleVideoPlacementService,
		private linkedInPlacementMapper: LinkedInPlacementMappersService,
		public adPlacementTemplates: AdPlacementTemplateService,
		public appEntityServices: AppEntityServices,
	) {}

	public loadCombinedContentTemplates(
		placements: ILinkedInPlacementVM[],
	): Observable<IAdContentTemplateVM[]> {
		return of(
			this.linkedInPlacementMapper.mapCombinedContentTemplates(
				placements,
			),
		);
	}

	public getPlacements(): void {
		this.appEntityServices.linkedInEntity.singleImagePlacement.getAll();
	}

	public loadPlacements(): Observable<IPlacementOptionsVM[]> {
		return this.appEntityServices.linkedInEntity.singleVideoPlacement.entities$.pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementOptions[]) =>
				this.linkedInPlacementMapper.mapPlacementsWithRenderingOptions(
					placements,
				),
			),
		);
	}

	public loadPlacementsByAdId(
		adId: string,
	): Observable<ILinkedInPlacementVM[]> {
		return this.adPlacementTemplates.loadPlacementsByAdId(adId).pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementBaseVM[]) =>
				this.linkedInPlacementMapper.mapPlacements(placements),
			),
		);
	}

	public getAspectRatioGroupName(aspectGroupKey: string): string {
		if (aspectGroupKey === '9:16') {
			return 'Select creative (9.16) for LinkedIn, ####';
		} else if (aspectGroupKey === '16:9') {
			return 'Select creative (16:9) for LinkedIn';
		} else if (aspectGroupKey === '1:1') {
			return 'Select creative (1:1) for ####';
		} else if (aspectGroupKey === '(1.91:1') {
			return 'Select creative (1.91:1) for ####';
		} else {
			return '';
		}
	}

	public getSupportedPlacements(
		adgroup: IAdGroupVM,
		ad: ILinkedInAdVM,
	): Observable<ILinkedInPlacementVM[]> {
		const adPlacements$ = this.loadPlacementsByAdId(ad.id);

		return adPlacements$.pipe(
			map((placements: [ILinkedInPlacementVM]) =>
				placements.filter((placement) =>
					adgroup?.supportedPlacementsIds?.includes(placement.id),
				),
			),
		);
	}
}
