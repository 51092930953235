import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';
import { Observable } from 'rxjs';
import { IBrandVM } from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'in-brand-header',
	templateUrl: './in-brand-header.component.html',
	styleUrls: ['./in-brand-header.component.scss'],
	standalone: true,
	imports: [BrandLogoComponent, CommonModule, TextPipe, UIModule],
})
export class LinkedInBrandHeaderComponent {
	@Input() public isSmall: boolean;
	@Input() public isSponsored: boolean;
	@Input() public withInfo: boolean;
	@Input() public withFollowers: boolean;

	public brand$: Observable<IBrandVM>;

	constructor(private readonly appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
