import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILinkedInCampaignGroup } from 'src/app/core/models/linkedin/in-campaign-group.model';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { ILinkedInCampaignGroupVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign-group.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignGroupBaseService extends BaseFeatureService<ILinkedInCampaignGroupVM> {
	constructor(public appEntityServices: AppEntityServices) {
		super();
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
	}): void {
		this.appEntityServices.linkedInEntity.campaignGroup.getWithQuery(
			params,
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ILinkedInCampaignGroup> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<ILinkedInCampaignGroup[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<ILinkedInCampaignGroup> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ILinkedInCampaignGroup[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
