import { Injectable } from '@angular/core';
import { ILinkedInCampaignGroup } from 'src/app/core/models/linkedin/in-campaign-group.model';
import { ILinkedInCampaignGroupVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign-group.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignGroupMapperService {
	public toCampaignGroupVM(
		campaignGroup: ILinkedInCampaignGroup,
	): ILinkedInCampaignGroupVM {
		const campaignGroupVM: ILinkedInCampaignGroupVM = {
			id: campaignGroup.id,
			name: campaignGroup.name,
			adAccountId: campaignGroup.adAccountId,
			integrationId: campaignGroup.integrationId,
		};

		return campaignGroupVM;
	}
}
