import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FacebookAdSetPlacementsEntityService } from './ad-set-placement/fb-ad-set-placements-entity.service';
import { FacebookAdSetPlacementDataService } from './ad-set-placement/fb-ad-set-placement-data.service';
import { FacebookPageEntityService } from './page/fb-pages-entity.service';
import { FacebookPageDataService } from './page/fb-page-data.service';
import { FacebookAdAccountDataService } from './ad-account/fb-ad-account-data.service';
import { FacebookAdAccountsEntityService } from './ad-account/fb-ad-accounts-entity.service';
import { FacebookAdFormatDataService } from './ad-format/fb-ad-format-data.service';
import { FacebookAdFormatsEntityService } from './ad-format/fb-ad-formats-entity.service';
import { FacebookAdSetDataService } from './ad-set/fb-ad-set-data.service';
import { FacebookAdSetsEntityService } from './ad-set/fb-ad-sets-entity.service';
import { FacebookCampaignDataService } from './campaign/fb-campaign-data.service';
import { FacebookCampaignsEntityService } from './campaign/fb-campaigns-entity.service';
import { FacebookInstagramAccountDataService } from './instagram-account/fb-instagram-account-data.service';
import { FacebookInstagramAccountEntityService } from './instagram-account/fb-instagram-accounts-entity.service';
import { FacebookSinglePlacementDataService } from './single-placement/fb-single-placement-data.service';
import { FacebookSinglePlacementsEntityService } from './single-placement/fb-single-placements-entity.service';
import { FacebookCarouselPlacementDataService } from './carousel-placement/fb-carousel-placement-data.service';
import { FacebookCarouselPlacementsEntityService } from './carousel-placement/fb-carousel-placements-entity.service';
import { EntityDataService } from '@ngrx/data';
import {
	FACEBOOK_ADACCOUNT_ENTITY,
	FACEBOOK_ADFORMAT_ENTITY,
	FACEBOOK_ADSET_ENTITY,
	FACEBOOK_ADSET_PLACEMENT_ENTITY,
	FACEBOOK_CAMPAIGN_ENTITY,
	FACEBOOK_PAGE_ENTITY,
	FACEBOOK_INSTAGRAM_ACCOUNT_ENTITY,
	FACEBOOK_CAROUSEL_PLACEMENT_ENTITY,
	FACEBOOK_SINGLE_PLACEMENT_ENTITY,
	FACEBOOK_CTA_ENTITY,
} from 'src/app/state/app.state';
import { FacebookCtaDataService } from './cta/fb-cta-data.service';
import { FacebookCtaEntityService } from './cta/fb-cta-entity.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		FacebookAdAccountDataService,
		FacebookAdAccountsEntityService,
		FacebookAdFormatDataService,
		FacebookAdFormatsEntityService,
		FacebookAdSetDataService,
		FacebookAdSetPlacementDataService,
		FacebookAdSetsEntityService,
		FacebookAdSetPlacementsEntityService,
		FacebookCampaignDataService,
		FacebookCampaignsEntityService,
		FacebookSinglePlacementDataService,
		FacebookSinglePlacementsEntityService,
		FacebookCarouselPlacementDataService,
		FacebookCarouselPlacementsEntityService,
		FacebookPageDataService,
		FacebookPageEntityService,
		FacebookInstagramAccountDataService,
		FacebookInstagramAccountEntityService,
		FacebookCtaDataService,
		FacebookCtaEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityFacebookModule {
	private facebookAdAccountDataService: FacebookAdAccountDataService;
	private facebookAdFormatDataService: FacebookAdFormatDataService;
	private facebookAdSetDataService: FacebookAdSetDataService;
	private facebookAdSetPlacementDataService: FacebookAdSetPlacementDataService;
	private facebookCampaignDataService: FacebookCampaignDataService;
	private facebookSinglePlacementDataService: FacebookSinglePlacementDataService;
	private facebookCarouselPlacementDataService: FacebookCarouselPlacementDataService;
	private facebookPageDataService: FacebookPageDataService;
	private facebookInstagramAccountDataService: FacebookInstagramAccountDataService;
	private facebookCtaDataService: FacebookCtaDataService;

	constructor(
		entityDataService: EntityDataService,
		private injector: Injector,
	) {
		this.facebookAdAccountDataService = this.injector.get(
			FacebookAdAccountDataService,
		);
		this.facebookAdFormatDataService = this.injector.get(
			FacebookAdFormatDataService,
		);
		this.facebookAdSetDataService = this.injector.get(
			FacebookAdSetDataService,
		);
		this.facebookAdSetPlacementDataService = this.injector.get(
			FacebookAdSetPlacementDataService,
		);
		this.facebookCampaignDataService = this.injector.get(
			FacebookCampaignDataService,
		);
		this.facebookSinglePlacementDataService = this.injector.get(
			FacebookSinglePlacementDataService,
		);
		this.facebookCarouselPlacementDataService = this.injector.get(
			FacebookCarouselPlacementDataService,
		);
		this.facebookPageDataService = this.injector.get(
			FacebookPageDataService,
		);
		this.facebookInstagramAccountDataService = this.injector.get(
			FacebookInstagramAccountDataService,
		);
		this.facebookCtaDataService = this.injector.get(FacebookCtaDataService);

		entityDataService.registerService(
			FACEBOOK_ADACCOUNT_ENTITY,
			this.facebookAdAccountDataService,
		);
		entityDataService.registerService(
			FACEBOOK_ADFORMAT_ENTITY,
			this.facebookAdFormatDataService,
		);
		entityDataService.registerService(
			FACEBOOK_ADSET_ENTITY,
			this.facebookAdSetDataService,
		);
		entityDataService.registerService(
			FACEBOOK_ADSET_PLACEMENT_ENTITY,
			this.facebookAdSetPlacementDataService,
		);
		entityDataService.registerService(
			FACEBOOK_CAMPAIGN_ENTITY,
			this.facebookCampaignDataService,
		);
		entityDataService.registerService(
			FACEBOOK_SINGLE_PLACEMENT_ENTITY,
			this.facebookSinglePlacementDataService,
		);
		entityDataService.registerService(
			FACEBOOK_CAROUSEL_PLACEMENT_ENTITY,
			this.facebookCarouselPlacementDataService,
		);
		entityDataService.registerService(
			FACEBOOK_PAGE_ENTITY,
			this.facebookPageDataService,
		);
		entityDataService.registerService(
			FACEBOOK_INSTAGRAM_ACCOUNT_ENTITY,
			this.facebookInstagramAccountDataService,
		);
		entityDataService.registerService(
			FACEBOOK_CTA_ENTITY,
			this.facebookCtaDataService,
		);
	}
}
