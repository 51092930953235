import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ILinkedInCampaignPlacements } from 'src/app/core/models/linkedin/in-campaign-placement.model';
import { LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class LinkedInCampaignPlacementsEntityService extends EntityCollectionServiceBase<ILinkedInCampaignPlacements> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY, serviceElementsFactory);
	}
}
