<ng-container>
    <div class="container container-left">
        <campaign-header *ngIf="showCampaignHeader; else exitCampaignIcon" />

        <ng-template #exitCampaignIcon>
            <div
                *ngIf="(user$ | async) as user"
                id="interaction-exit-campaign"
                class="exit-icon"
                (click)="exitCampaign(user)">
                <ui-svg-icon class="close" icon="close" />
            </div>
        </ng-template>

        <environment-picker />
    </div>

    <div *ngIf="showAdViews" class="container container-center">
        <ui-button-group
            id="interaction-change-ad-view"
            [options]="viewOptions"
            [value]="selectedView"
            (valueChange)="onViewChanged($event)" />
    </div>

    <div *ngIf="showPublishButtons" class="container container-right">
        <ui-button
            [text]="('Shared.PUBLISH_ACTIVE' | text)"
            icon="upload"
            [disabled]="true" />

        <ui-button
            [text]="('Shared.PUBLISH_PAUSED' | text)"
            icon="upload"
            [disabled]="true" />
        <ns-notifications-component *ffFeatureFlag="'NotificationService-Integrations'" />
    </div>

    <div *ngIf="!showPublishButtons && !showAdViews" class="container container-right">
        <ns-notifications-component *ffFeatureFlag="'NotificationService-Integrations'" />
    </div>
</ng-container>
