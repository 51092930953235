import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TIKTOK_CTA_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ICta } from 'src/app/core/models/shared/cta.model';
import { NetworkEnum } from 'src/app/presentation/view-models';

@Injectable()
export class TiktokCtaDataService extends DefaultDataService<ICta> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_CTA_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<ICta[]> {
		return this.appCoreServices.socialCampaignApiService.cta.getCtas(
			NetworkEnum.tiktok,
		);
	}
}
