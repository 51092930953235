import { ComponentType } from '@angular/cdk/portal';
import { LinkedInPlacementBaseDirective } from '../placements/directives/in-placement-base.directive';
import { LinkedInSupportedPlacements } from '../enums/in-supported-placement.enum';
import { LinkedInLinkedInComponent } from '../placements/linkedin/in-linkedin.component';
import { LinkedInAudienceNetworkComponent } from '../placements/linkedin-audience-network/in-linkedin-audience-network.component';

export const SinglePlacements: Record<
	string,
	ComponentType<LinkedInPlacementBaseDirective>
> = {
	[LinkedInSupportedPlacements.LinkedInLinkedIn]: LinkedInLinkedInComponent,
	[LinkedInSupportedPlacements.LinkedInAudienceNetwork]:
		LinkedInAudienceNetworkComponent,
};
