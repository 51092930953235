export enum LinkedInAdContentIdEnum {
	callToAction = 'call-to-action',
	leadForm = 'lead-form',
	introductoryText = 'introductory-text',
	imageAltText = 'image-alt-text',
	description = 'description',
	destinationUrl = 'destination-url',
	headline = 'headline',
	targetUrl = 'target-url',
}
