import { CommonFeatureModule } from './common/common-feature.module';
import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SocialAccountFeatureModule } from './account/social-account-feature.module';
import { SocialAdFeatureModule } from './ad/social-ad-feature.module';
import { AppFeatureServices } from './app-feature.service';
import { SocialCampaignFeatureModule } from './campaign/social-campaign-feature.module';
import { FacebookFeatureModule } from './facebook/facebook-feature.module';
import { SocialPublishFeatureModule } from './publish/social-publish-feature.module';
import { StudioFeatureModule } from './studio/studio-feature.module';
import { TiktokFeatureModule } from './tiktok/tt-feature.module';
import { LinkedInFeatureModule } from './linkedin/in-feature.module';

@NgModule({
	declarations: [],
	imports: [
		SocialAdFeatureModule,
		SocialAccountFeatureModule,
		SocialCampaignFeatureModule,
		FacebookFeatureModule,
		TiktokFeatureModule,
		LinkedInFeatureModule,
		StudioFeatureModule,
		SocialPublishFeatureModule,
		CommonFeatureModule,
	],
	providers: [
		AppFeatureServices,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppFeatureModule {}
