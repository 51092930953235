import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IAd } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { AD_ENTITY } from 'src/app/state/app.state';
import { Observable } from 'rxjs';
import { IDuplicateAd } from 'src/app/core/models/ad/ad/duplicate-ad.model';

@Injectable()
export class AdsEntityService extends EntityCollectionServiceBase<IAd> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_ENTITY, serviceElementsFactory);
	}

	public updatePlacement(
		adId: string,
		placement: IAdPlacement,
	): Observable<IAd> {
		return this.appCoreServices.socialCampaignApiService.customization.updateAdPlacement(
			adId,
			placement,
		);
	}

	public duplicate(ads: IDuplicateAd[]): Observable<string[]> {
		return this.appCoreServices.socialCampaignApiService.ad.duplicate(ads);
	}
}
