import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter, map, Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Component({
	selector: 'in-cta-button',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './in-cta-button.component.html',
	styleUrls: ['./in-cta-button.component.scss'],
})
export class LinkedInCtaButtonComponent implements OnChanges {
	@Input({ required: true }) public ctaValue: string;

	public ctaName$: Observable<string>;

	constructor(private readonly appFeatureService: AppFeatureServices) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.ctaValue) {
			this.ctaName$ = this.appFeatureService.linkedInFeature.account.cta
				.loadAllCtas()
				.pipe(
					filter((ctas) => !!ctas),
					map(
						(ctas) =>
							ctas.find((cta) => cta.id === this.ctaValue)?.name,
					),
				);
		}
	}
}
