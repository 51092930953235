import { Injectable } from '@angular/core';
import { IAd, IAdContentDictionary } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import {
	AdFormatEnum,
	IAdContentTemplateVM,
	IAdPlacementBaseVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { ILinkedInAdVM } from 'src/app/presentation/view-models/linkedin/ad/in-ad.vm';
import { LinkedInAdListMappersService } from './in-ad-list.mapper';

@Injectable({
	providedIn: 'root',
})
export class LinkedInAdMappersService {
	constructor(
		private creativesMapper: CreativesAdMapperService,
		public linkAdListMapper: LinkedInAdListMappersService,
	) {}

	public toAdVM(ad: IAd): ILinkedInAdVM {
		const adVM: ILinkedInAdVM = {} as ILinkedInAdVM;

		if (ad.default.content) {
			adVM.defaultContent = { ...ad.default.content };
		}

		adVM.id = ad.id;
		adVM.adFormatId = ad.adFormatId;
		adVM.adGroupId = ad.adGroupId;
		adVM.languageId = ad.languageId;
		adVM.name = ad.name;
		adVM.placements = ad.placements?.map((placement) =>
			this.mapAdPlacement(placement),
		);

		if (ad.default.creatives) {
			adVM.adCreatives = ad.default.creatives.map((creative) =>
				this.creativesMapper.toCreativeVM(creative),
			);
		}

		return adVM;
	}

	public toAd(adViewModel: ILinkedInAdVM): IAd {
		const ad: IAd = {
			id: adViewModel.id,
			name: adViewModel.name,
			adFormatId: adViewModel.adFormatId,
			languageId: adViewModel.languageId,
			adGroupId: adViewModel.adGroupId,
			default: {
				content: null,
				creatives: adViewModel?.adCreatives
					? adViewModel.adCreatives.map((creative) =>
							this.creativesMapper.toCreative(
								creative,
								adViewModel.adFormatId ===
									AdFormatEnum.singleVideo
									? MediaTypeEnum.Video
									: MediaTypeEnum.Image,
							),
						)
					: [],
			},
		} as IAd;

		return ad;
	}

	private mapAdPlacement(adPlacement: IAdPlacement): IAdPlacementBaseVM {
		return {
			placementId: adPlacement.placementId,
			creative: adPlacement.creative,
			content: adPlacement?.content,
			isCustomized: adPlacement?.isCustomized,
		};
	}

	public mapPlacementContentToValidateAllProperties(
		adContent: IAdContentDictionary,
		contentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const adContentToValidate: IAdContentDictionary = {};

		contentTemplates.forEach((template) => {
			if (!adContent[template.id]) {
				return;
			}

			adContentToValidate[template.id] = adContent[template.id];
		});

		return adContentToValidate;
	}
}
