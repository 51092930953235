import { Injectable } from '@angular/core';
import {
	ClvObjectQuery,
	ExObjectItem,
	ExObjectResult,
	IClvCreativeSetsResponse,
	IClvItem,
	ICoreCreativeSetData,
	ICreativeSetFolder,
} from '../../../models';
import { CreativeSetFolderAdapter } from '../../../utilities/folderable-creativeset.adapter';

@Injectable({
	providedIn: 'root',
})
export class CommonListViewReponseMapperService {
	public deserializeCreativeSetResponse(
		response: IClvCreativeSetsResponse,
	): ICreativeSetFolder[] {
		const result: ExObjectResult<ICoreCreativeSetData> =
			new ExObjectResult<ICoreCreativeSetData>();

		const items: Array<ExObjectItem<ICoreCreativeSetData>> =
			response.items.map((item: IClvItem) => {
				const creativeSet: ICoreCreativeSetData = {
					creativesetId: item.externalId,
					folder: item.parentFolderId,
					id: item.id,
					name: item.name,
					thumb: item.thumb,
					created: new Date(item.createdAt),
					modified: new Date(item.modifiedAt),
					createdBy: {
						name: item.createdBy,
						email: '',
						id: '',
						profile: '',
					},
					publicId: '',
					slug: '',
				};
				const exItem: ExObjectItem<ICoreCreativeSetData> =
					new ExObjectItem<ICoreCreativeSetData>();
				exItem.data = creativeSet;
				exItem.isFolder = !!(item.type === 'Folder');

				return exItem;
			});

		result.items = items;

		const creativeSets: ICreativeSetFolder[] = result.items.map(
			(item: any) =>
				CreativeSetFolderAdapter.mapExCreativeSetToFolder(item),
		);

		const sortFn = (a: ICreativeSetFolder, b: ICreativeSetFolder): number =>
			a.name.localeCompare(b.name);

		// sort A-Z
		const folders = creativeSets
			.filter((item) => item.isFolder)
			.sort(sortFn);
		const notFolders = creativeSets
			.filter((item) => !item.isFolder)
			.sort(sortFn);

		return [...folders, ...notFolders];
	}

	public getCreativeSetQueryCLV(
		search: boolean = false,
		queryValue?: string,
		folderId?: string,
	): ClvObjectQuery {
		const query: ClvObjectQuery = new ClvObjectQuery();
		query.folderId = this.getSearchFolderValue(search, folderId);
		query.filters = search ? { name: queryValue } : undefined;
		query.paging = { pageNumber: 1, pageSize: 50000 };
		query.sort = { fieldName: 'name', order: 'ASC' };

		return query;
	}

	private getSearchFolderValue(search: boolean, folderId: string): string {
		if (search) {
			return undefined;
		}

		return folderId ? folderId : undefined;
	}
}
