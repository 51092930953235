import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ICta } from 'src/app/core/models/shared/cta.model';
import { TIKTOK_CTA_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class TiktokCtaEntityService extends EntityCollectionServiceBase<ICta> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(TIKTOK_CTA_ENTITY, serviceElementsFactory);
	}
}
