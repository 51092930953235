import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAdLogVM,
	IPublishLogItemVM,
	NavigationOrigin,
	PublishLogDefaultFilterEnum,
} from '../../view-models';
import { PublishLogsStore } from './publish-logs.component.store';

@Component({
	selector: 'publish-logs',
	templateUrl: './publish-logs.component.html',
	styleUrls: ['./publish-logs.component.scss'],
	standalone: false,
	providers: [PublishLogsStore],
})
export class PublishLogsComponent implements OnInit {
	public publishLogs$: Observable<IPublishLogItemVM[]>;
	public publishLogAds$: Observable<IAdLogVM[]>;
	public selectedPublishLogAd$: Observable<IAdLogVM>;
	public publishLogStatuses$: Observable<string[]>;
	public selectedPublishLogStatus$: Observable<string>;
	public publishLogUsers$: Observable<string[]>;
	public selectedPublishLogUser$: Observable<string>;
	private viewToNavigateBackTo: NavigationOrigin = NavigationOrigin.List;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private publishLogsStore: PublishLogsStore,
		private activatedRoute: ActivatedRoute,
		private router: Router,
	) {}

	public ngOnInit(): void {
		this.publishLogs$ = this.publishLogsStore.loadPublishLogs();
		this.publishLogAds$ = this.publishLogsStore.loadPublishLogAds();
		this.publishLogStatuses$ =
			this.publishLogsStore.loadPublishLogStatuses();
		this.selectedPublishLogStatus$ =
			this.publishLogsStore.loadSelectedPublishLogStatus();

		this.publishLogUsers$ = this.publishLogsStore.loadPublishLogUsers();

		this.selectedPublishLogUser$ =
			this.publishLogsStore.loadSelectedPublishLogUser();

		this.selectedPublishLogAd$ =
			this.publishLogsStore.loadSelectedPublishLogAd();
	}

	public selectedPublishLogAd(ad: IAdLogVM): void {
		this.publishLogsStore.setSelectedPublishLogAd(ad);

		this.router.navigate([`../../${ad.id}/publish-logs`], {
			relativeTo: this.activatedRoute,
		});
		this.appFeatureServices.publishFeature.publishLogs.getWithQuery(ad.id);
		this.publishLogsStore.setPublishLogs(ad.id);
		this.publishLogsStore.setSelectedPublishLogStatus(
			PublishLogDefaultFilterEnum.AllStatuses,
		);
		this.publishLogsStore.setSelectedPublishLogUser(
			PublishLogDefaultFilterEnum.AllUsers,
		);
	}

	public selectedPublishLogStatus(status: string): void {
		this.publishLogsStore.setSelectedPublishLogStatus(status);
	}

	public selectedPublishLogUser(user: string): void {
		this.publishLogsStore.setSelectedPublishLogUser(user);
	}

	public backToAdList(): void {
		const navigationCommand =
			`../../../${this.viewToNavigateBackTo}`.toLocaleLowerCase();

		this.router.navigate([navigationCommand], {
			relativeTo: this.activatedRoute.parent,
		});
	}
}
