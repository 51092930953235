import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LINKEDIN_CAMPAIGN_GROUP_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ILinkedInCampaignGroup } from 'src/app/core/models/linkedin/in-campaign-group.model';

@Injectable()
export class LinkedInCampaignGroupDataService extends DefaultDataService<ILinkedInCampaignGroup> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(LINKEDIN_CAMPAIGN_GROUP_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
	}): Observable<ILinkedInCampaignGroup[]> {
		return this.appCoreServices.socialCampaignApiService.linkedin.campaignGroupApi.getCampaignGroups(
			params.integrationId,
			params.adAccountId,
		);
	}
}
