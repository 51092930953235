import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Dictionary } from '@ngrx/entity';
import { Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import {
	filter,
	first,
	map,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ConnectionStatusEnum } from '../core/models/account/integration/connection-status.enum';
import { AppFeatureServices } from '../features/app-feature.service';
import {
	AdFormatEnum,
	IAdGroupVM,
	IAdVM,
	NetworkEnum,
	PublishAsEnum,
} from './view-models';
import { IAd, IAdStatus } from '../core/models';

export interface ISocialCampaignPageState {
	adGroupAdListExpansions: Dictionary<boolean>;
	adGroupAdPreviewExpansions: Dictionary<boolean>;
	previewUrls: Dictionary<string>;
	selectedAdIds: Dictionary<string[]>;
	expandedAdIds: Dictionary<string>;
	selectedPublishAs: PublishAsEnum;
}

@Injectable()
export class SocialCampaignPageStore
	extends ComponentStore<ISocialCampaignPageState>
	implements OnDestroy
{
	private unsubscribe$ = new Subject<void>();

	constructor(private appFeatureServices: AppFeatureServices) {
		super({
			adGroupAdListExpansions: {},
			adGroupAdPreviewExpansions: {},
			previewUrls: {},
			selectedAdIds: {},
			expandedAdIds: {},
			selectedPublishAs: undefined,
		});

		this.initialize();
	}

	public initialize(): void {
		this.appFeatureServices.accountFeature.network.getAll();
		this.appFeatureServices.facebookFeature.account.cta.getCta();
		this.appFeatureServices.tiktokFeature.account.cta.getCta();
		this.appFeatureServices.linkedInFeature.account.cta.getCta();
		this.appFeatureServices.adFeature.adGroup
			.loadAdGroups()
			.pipe(
				filter((adGroup) => !!adGroup[0]),
				first(),
				takeUntil(this.unsubscribe$),
			)
			.subscribe((adGroups) => {
				this.updateAdGroupAdListExpansion({
					id: adGroups[0].id,
					isExpanding: true,
				});
				this.updateAdGroupAdPreviewExpansion({
					id: adGroups[0].id,
					isExpanding: true,
				});
				this.getAdGroupConnections(adGroups);
				this.appFeatureServices.adFeature.adGroup.getById(
					adGroups[0].id,
				);
				this.appFeatureServices.adFeature.ad
					.getAdsByAdGroupId(adGroups[0].id)
					.subscribe((ads) => {
						this.getAdCreatives(ads);
						this.getAdPublishStatus(adGroups[0]);
					});

				const adGroup$ = this.appFeatureServices.adFeature.adGroup
					.loadById(adGroups[0].id)
					.pipe(
						filter((adGroup) => !!adGroup.connection),
						take(1),
					);

				const connectionStatus$ = adGroup$.pipe(
					switchMap((adGroup) =>
						this.appFeatureServices.accountFeature.integration.loadConnectionStatus(
							adGroup.connection?.integrationId,
						),
					),
					take(1),
				);

				const connection$ =
					this.appFeatureServices.adFeature.adGroupConnection
						.loadById(adGroups[0].id)
						.pipe(
							filter((connection) => !!connection),
							take(1),
						);

				combineLatest([connection$, connectionStatus$])
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(([connection, status]) => {
						if (
							status.connectionStatus ===
							ConnectionStatusEnum.Connected
						) {
							if (
								adGroups[0].networkId === NetworkEnum.facebook
							) {
								this.appFeatureServices.facebookFeature.account.adSet.getWithQuery(
									{
										integrationId: connection.integrationId,
										campaignId: connection.campaignId,
									},
								);
								this.appFeatureServices.facebookFeature.account.adSetPlacement.getWithQuery(
									{
										integrationId: connection.integrationId,
										campaignId: connection.campaignId,
										adSetId: connection.adSetId,
									},
								);
							}
						}
					});
			});
	}

	public readonly setSelectedPublishAs = this.effect(
		(publishAs$: Observable<PublishAsEnum>) =>
			publishAs$.pipe(
				tap((publishAs: PublishAsEnum) => {
					this.updatePublishAs(publishAs);
				}),
			),
	);

	public readonly updateAdGroupAdListExpansion = this.updater(
		(
			state: ISocialCampaignPageState,
			action: { id: string; isExpanding: boolean },
		) => ({
			...state,
			adGroupAdListExpansions: {
				...state.adGroupAdListExpansions,
				[action.id]: action.isExpanding,
			},
		}),
	);

	public readonly updateAdGroupAdPreviewExpansion = this.updater(
		(
			state: ISocialCampaignPageState,
			action: { id: string; isExpanding: boolean },
		) => ({
			...state,
			adGroupAdPreviewExpansions: {
				...state.adGroupAdPreviewExpansions,
				[action.id]: action.isExpanding,
			},
		}),
	);

	public readonly updateSelectedAdsForAdGroup = this.updater(
		(
			state: ISocialCampaignPageState,
			action: { adGroupId: string; selectedAdIds: string[] },
		) => ({
			...state,
			selectedAdIds: {
				...state.selectedAdIds,
				[action.adGroupId]: action.selectedAdIds,
			},
		}),
	);

	public readonly updateExpandedAdForAdGroup = this.updater(
		(
			state: ISocialCampaignPageState,
			action: { adGroupId: string; expandedAdId: string },
		) => ({
			...state,
			expandedAdIds: {
				[action.adGroupId]: action.expandedAdId,
			},
		}),
	);

	public readonly updatePublishAs = this.updater(
		(state: ISocialCampaignPageState, publishAs: PublishAsEnum) => ({
			...state,
			selectedPublishAs: publishAs,
		}),
	);

	public readonly resetSelectedAds = this.updater(
		(state: ISocialCampaignPageState) => ({
			...state,
			selectedAdIds: {},
		}),
	);

	public readonly resetExpandedAdGroupInAdList = this.updater(
		(state: ISocialCampaignPageState) => ({
			...state,
			adGroupAdListExpansions: {},
		}),
	);

	public loadAdGroupListExpansion(id: string): Observable<boolean> {
		return this.select((state) => state.adGroupAdListExpansions[id]);
	}

	public loadAdGroupAdPreviewExpansion(id: string): Observable<boolean> {
		return this.select((state) => state.adGroupAdPreviewExpansions[id]);
	}

	public loadSelectedAdsForAdGroup(adGroupId: string): Observable<string[]> {
		return this.select((state) => state.selectedAdIds[adGroupId]);
	}

	public loadSelectedPublishAs(): Observable<PublishAsEnum> {
		return this.select((state) => state.selectedPublishAs);
	}

	public loadExpandedAdIdForAdGroup(adGroupId: string): Observable<string> {
		return this.select((state) => state.expandedAdIds[adGroupId]);
	}

	public loadNumberOfSelectedAds(): Observable<number> {
		return this.select((state) => state.selectedAdIds).pipe(
			map((selectedAdIds) => {
				const values = Object.values(selectedAdIds);
				let count = 0;

				for (const value of values) {
					count = count + value.length;
				}

				return count;
			}),
		);
	}

	public loadNumberOfSelectedCarouselAds(): Observable<number> {
		return this.select((state) => state.selectedAdIds).pipe(
			switchMap((selectedAdIds: Dictionary<string[]>) => {
				const ids = [].concat(...Object.values(selectedAdIds));

				return this.appFeatureServices.adFeature.ad.loadByIds(ids);
			}),
			map(
				(ads) =>
					ads.filter((ad) => ad.adFormatId === AdFormatEnum.carousel)
						.length,
			),
		);
	}

	public loadNumberOfSelectedLinkedInAds(
		adGroups: IAdGroupVM[],
	): Observable<boolean> {
		return this.select((state) => state.selectedAdIds).pipe(
			switchMap((selectedAdIds: Dictionary<string[]>) => {
				const ids = [].concat(...Object.values(selectedAdIds));

				return this.appFeatureServices.adFeature.ad.loadByIds(ids);
			}),
			map((ads) => {
				const adGroupIds = [];
				ads.forEach((ad) => {
					if (!adGroupIds.includes(ad.adGroupId)) {
						adGroupIds.push(ad.adGroupId);
					}
				});

				const selectedAdGroups = adGroups.filter((adGroup) =>
					adGroupIds.includes(adGroup.id),
				);

				return (
					selectedAdGroups.filter(
						(adGroup) => adGroup.networkId === NetworkEnum.linkedin,
					).length > 0
				);
			}),
		);
	}

	public loadSelectedAdsWithAdGroup(): Observable<Dictionary<string[]>> {
		return this.select((state) => state.selectedAdIds);
	}

	public areSelectedAdsValidForBulkEdit(
		adIds: string[],
		adGroupIds: string[],
	): Observable<boolean> {
		return combineLatest([
			this.appFeatureServices.adFeature.adGroup.loadByIds(adGroupIds),
			this.appFeatureServices.adFeature.ad.loadByIds(adIds),
			this.appFeatureServices.adFeature.adGroupConnection.loadByIds(
				adGroupIds,
			),
		]).pipe(
			map(([adGroups, ads, connections]) =>
				this.appFeatureServices.adFeature.ad.validateAdsForBulkEditing(
					ads,
					adGroups,
					connections,
				),
			),
			switchMap((validationResult) => validationResult),
		);
	}

	public areSelectedAdsValidForDuplication(): Observable<boolean> {
		return this.loadUniqueSelectedAdsNetworks().pipe(
			switchMap((networks) => {
				if (networks.length !== 1) {
					return of(false);
				}

				if (networks[0] !== NetworkEnum.linkedin) {
					return of(true);
				}

				return this.loadUniqueSelectedAdsFormats().pipe(
					map((selectedFormats) => selectedFormats.length === 1),
				);
			}),
		);
	}

	public loadSelectedAdsNetwork(): Observable<string> {
		return this.loadUniqueSelectedAdsNetworks().pipe(
			map((networks) => {
				if (networks.length !== 1) {
					return null;
				}

				return networks[0];
			}),
		);
	}

	public loadSelectedAdsFormat(): Observable<string | null> {
		return this.loadUniqueSelectedAdsFormats().pipe(
			map((formats) => {
				if (formats.length !== 1) {
					return null;
				}

				return formats[0];
			}),
		);
	}

	private loadUniqueSelectedAdsNetworks(): Observable<string[]> {
		return this.select((state) => state.selectedAdIds).pipe(
			map((selectedAdIds) => {
				const adGroups = [];

				for (const key in selectedAdIds) {
					const selectedAds = selectedAdIds[key];

					if (selectedAds.length > 0) {
						adGroups.push(key);
					}
				}
				return adGroups;
			}),
			switchMap((adGroupIds) => {
				if (adGroupIds.length === 0) {
					return of([]);
				}

				return this.appFeatureServices.adFeature.adGroup
					.loadByIds(adGroupIds)
					.pipe(
						map((selectedAdGroups) => {
							const networks = [];

							for (const group of selectedAdGroups) {
								networks.push(group.networkId);
							}

							return [...new Set(networks)];
						}),
					);
			}),
		);
	}

	private loadUniqueSelectedAdsFormats(): Observable<string[]> {
		return this.select((state) => state.selectedAdIds).pipe(
			map((selectedAdIds) => Object.values(selectedAdIds).flat()),
			switchMap((combinedAdsIds) =>
				this.appFeatureServices.adFeature.ad.loadByIds(combinedAdsIds),
			),
			map((ads: IAdVM[]) => {
				const adFormats = ads.map(({ adFormatId }) => adFormatId);

				return [...new Set(adFormats)];
			}),
		);
	}

	public loadSelectedAdsForScheduling(): Observable<Dictionary<string[]>> {
		return this.select((state) => state.selectedAdIds).pipe(
			withLatestFrom(this.loadSelectedPublishStatuses()),
			map(([selectedAdIds, statuses]) =>
				this.filterSelectedAdsForScheduling(selectedAdIds, statuses),
			),
		);
	}

	private loadSelectedPublishStatuses(): Observable<IAdStatus[]> {
		return this.select((state) => state.selectedAdIds).pipe(
			switchMap((selectedAdIds) => {
				const adIds = [].concat(...Object.values(selectedAdIds));
				const publishStatuses$ = adIds.map((adId) =>
					this.appFeatureServices.publishFeature.publish.loadAdStatusByAdId(
						adId,
					),
				);

				return forkJoin(publishStatuses$);
			}),
		);
	}

	private filterSelectedAdsForScheduling(
		selectedAdIds: Dictionary<string[]>,
		statuses: IAdStatus[],
	): Dictionary<string[]> {
		const selectedAdsForScheduling: Dictionary<string[]> = {};

		Object.entries(selectedAdIds).forEach(([adGroupId, adsIds]) => {
			adsIds.forEach((adId) => {
				const adCanBeUpdated = statuses.some(
					(status) => status.canBeUpdated && status.id === adId,
				);

				if (adCanBeUpdated) {
					selectedAdsForScheduling[adGroupId] =
						selectedAdsForScheduling[adGroupId] || [];
					selectedAdsForScheduling[adGroupId].push(adId);
				}
			});
		});

		return selectedAdsForScheduling;
	}

	public resetStateAfterPublish(): void {
		this.resetSelectedAds();
		this.resetExpandedAdGroupInAdList();
		this.appFeatureServices.adFeature.adGroup
			.loadAdGroups()
			.pipe(
				filter((adGroup) => !!adGroup[0]),
				first(),
			)
			.subscribe((adGroups) => {
				this.updateAdGroupAdListExpansion({
					id: adGroups[0].id,
					isExpanding: true,
				});
				this.getAdPublishStatus(adGroups[0]);
			});
	}

	private getAdPublishStatus(adGroup: IAdGroupVM): void {
		this.appFeatureServices.adFeature.ad
			.loadAdsIdsByAdGroupId(adGroup.id)
			.pipe(take(1))
			.subscribe((adIds) => {
				this.appFeatureServices.publishFeature.publish.getStatusByAdIds(
					adIds,
					adGroup.connection?.integrationId,
				);
				this.appFeatureServices.adFeature.adSchedule.getByIds(adIds);
			});
	}

	private getAdCreatives(ads: IAd[]): void {
		const creativeSetIds =
			this.appFeatureServices.adFeature.ad.loadUniqueCreativeIdsByAdPlacements(
				ads,
			);

		creativeSetIds.forEach((setId) => {
			this.appFeatureServices.studioFeature.creativeSetService.getById(
				setId,
			);
		});
	}

	private getAdGroupConnections(adGroups: IAdGroupVM[]): void {
		adGroups.forEach(({ id, connection }) => {
			if (connection) {
				this.appFeatureServices.adFeature.adGroupConnection.getById(id);
			}
		});
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
