import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { LinkedInCampaignGroupBaseService } from './in-campaign-group-base.service';
import { ILinkedInCampaignGroupVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign-group.vm';
import { LinkedInCampaignGroupMapperService } from '../../mappers/in-campaign-group.mapper';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignGroupService extends LinkedInCampaignGroupBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		private campaignGroupMapper: LinkedInCampaignGroupMapperService,
	) {
		super(appEntityServices);
	}

	public loadAllByAdAccountId(
		adAccountId: string,
	): Observable<ILinkedInCampaignGroupVM[]> {
		const campaignGroups$: Observable<ILinkedInCampaignGroupVM[]> =
			this.appEntityServices.linkedInEntity.campaignGroup.entities$;

		return campaignGroups$.pipe(
			filter((groups) => !!groups),
			map((groups) =>
				groups.filter((group) => group.adAccountId === adAccountId),
			),
			map((groups) =>
				groups.map((group) =>
					this.campaignGroupMapper.toCampaignGroupVM(group),
				),
			),
		);
	}

	public isLoadingCampaignGroups(): Observable<boolean> {
		return this.appEntityServices.linkedInEntity.campaignGroup.loading$;
	}
}
