import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { filter, map, Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Component({
	selector: 'fb-instagram-explore-home-cta-button',
	templateUrl: './cta-button.component.html',
	styleUrls: ['./cta-button.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FbInstagramExploreHomeCtaButtonComponent implements OnChanges {
	@Input({ required: true }) public ctaValue: string;

	public ctaName$: Observable<string>;

	constructor(private appFeatureService: AppFeatureServices) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.ctaValue) {
			this.ctaName$ = this.appFeatureService.facebookFeature.account.cta
				.loadAllCtas()
				.pipe(
					filter((ctas) => !!ctas),
					map(
						(ctas) =>
							ctas.find((cta) => cta.id === this.ctaValue)?.name,
					),
				);
		}
	}
}
