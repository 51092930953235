<div class="cta">
    <div class="cta__container" *ngIf="(ctaName$ | async) || ctaValue; else skeleton">
        <div>{{ (ctaName$ | async) || ctaValue }}</div>
        <ui-svg-icon icon="arrow-right"></ui-svg-icon>
    </div>

    <ng-template #skeleton>
        <div class="cta__skeleton"></div>
    </ng-template>
</div>
