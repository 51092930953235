import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LinkedInAccountFeatureService } from './account/in-account-feature.service';
import { LinkedInFeatureService } from './in-feature.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		LinkedInFeatureService,
		LinkedInAccountFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class LinkedInFeatureModule {}
