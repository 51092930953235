import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdFormatsMappersService } from '../mappers/ad-formats.mappers';
import { AdFormatsBaseService } from './ad-formats-base.service';
import { IAdFormat } from 'src/app/core/models';
import { ILinkedInAdFormatVM } from 'src/app/presentation/view-models/linkedin/in-ad-format.vm';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsService extends AdFormatsBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adFormatsMappers: AdFormatsMappersService,
	) {
		super(appEntityServices, adFormatsMappers);
	}

	public getAll(): void {
		this.appEntityServices.linkedInEntity.adFormat.getAll();
	}

	public isLoadingAdFormats(): Observable<boolean> {
		return this.appEntityServices.linkedInEntity.adFormat.loading$;
	}

	public loadAllExcept(
		adFormatsToExclude: string[],
	): Observable<ILinkedInAdFormatVM[]> {
		const adFormats$: Observable<ILinkedInAdFormatVM[]> =
			this.appEntityServices.linkedInEntity.adFormat.entities$;

		return adFormats$.pipe(
			filter((adFormats) => !!adFormats),
			map((adFormats: IAdFormat[]) =>
				!adFormatsToExclude.length
					? adFormats
					: adFormats.filter(
							({ id }) => !adFormatsToExclude.includes(id),
						),
			),
			map((adFormats: IAdFormat[]) =>
				adFormats.map((adFormat) =>
					this.adFormatsMappers.mapAdFormat(adFormat),
				),
			),
		);
	}
}
