<div *ngFor="let placementId of (placements$ | async)">
    <div
        *ngrxLet="placementsMetaData$ | async as placementsMetaData"
        class="item"
        [ngClass]="{'in-active': !(isPlacementMatchedCampaignObjective(placementId) | async), 'menu-active': activePlacement === placementId}"
        [uiTooltip]="'Shared.PLACEMENT_IS_MISSING' | text"
        [uiTooltipDisabled]="(isPlacementMatchedCampaignObjective(placementId) | async)"
        uiTooltipTrigger="hover"
        uiTooltipPosition="bottom"
        [uiTooltipHideArrow]="true"
        [uiTooltipDelay]="0"
        [in-ad-preview]="{inPreview,  targetUrl: placementsMetaData?.[placementId]?.targetUrl}"
        (in-ad-preview-enter)="showTargetUrl(placementId)"
        (in-ad-preview-leave)="hideTargetUrl()">
        <placement-header>
            <ng-container role="title">
                <placement-title
                    [isCustomized]="placementsMetaData?.[placementId]?.isCustomized"
                    [assignedCreative]="placementsMetaData?.[placementId]?.creative"
                    [adFormatId]="placementsMetaData?.[placementId]?.formatId"
                    [placementId]="placementId" />
            </ng-container>
            <ng-container role="actions">
                <ng-container
                    *ngIf="!inPreview && (placementsMetaData?.[placementId]?.selectedMediaType === mediaType.Video)">
                    <placement-sound
                        *ngIf="(placementsSound$ | async) as placementsSound"
                        [ngClass]="placementsSound[placementId] === soundEnum.On || activePlacement === placementId ? 'action-visible' : 'action-invisible'"
                        [sound]="placementsSound[placementId]"
                        (onSoundChanged)="onSoundChange($event, placementId)" />
                </ng-container>
                <placement-options
                    *ngIf="!isBulk && !inPreview && ((adGroupConnection$ | async)?.promotedObjectType !== promotedObjectTypeEnum.locationPage)"
                    [ngClass]="activePlacement === placementId ? 'action-visible' : 'action-invisible'"
                    [placementId]="placementId"
                    [adId]="placementsMetaData?.[placementId].adId"
                    [supportedPlacements]="(supportedPlacements$ | async )"
                    (isOpenMenuEvent)="openMenu($event)" />
            </ng-container>
        </placement-header>

        <div class="placement">
            <fb-placements-wrapper
                [placementId]="placementId"
                [adFormatId]="placementsMetaData?.[placementId]?.formatId"
                [in-ad-placement-preview]="{inPreview, adId: placementsMetaData?.[placementId]?.adId, adGroupId: placementsMetaData?.[placementId]?.adGroupId}">
                <target-url
                    *ngIf="hoveredPlacement === placementId"
                    [targetUrl]="placementsMetaData?.[placementId]?.targetUrl" />
            </fb-placements-wrapper>
        </div>
    </div>
</div>
