import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TruncatePipe } from 'src/app/presentation/pages/shared/pipes/truncate.pipe';

@Component({
	selector: 'in-introductory-text',
	templateUrl: './in-introductory-text.component.html',
	styleUrls: ['./in-introductory-text.component.scss'],
	standalone: true,
	imports: [CommonModule, TruncatePipe],
})
export class LinkedInIntroductoryTextComponent {
	@Input({ required: true }) public introductoryTextValue: string;
	public numberOfLines = 2;
}
