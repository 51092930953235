<ng-container>
    <div *ngIf="headlineValue; else skeleton">
        <div class="headline">{{ headlineValue | truncate: 30 }}</div>
    </div>
    <ng-template #skeleton>
        <div class="headline-default">
            <div class="line"></div>
        </div>
    </ng-template>
</ng-container>
