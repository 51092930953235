<ng-container>
    <div class="summary">
        <div class="header">
            <ui-svg-icon
                [ngClass]="{'is-expanded': isExpanded}"
                class="toggle-icon"
                icon="arrow-down">
            </ui-svg-icon>
            <div class="date">{{ publishLogItem.date | date:'yyyy-MM-dd HH:mm:ss' }}</div>
        </div>
        <div class="info">
            <div class="top row">
                <div class="cell">Ad name</div>
                <div class="cell">Format</div>
                <div class="cell">Language</div>
                <div class="cell">
                    {{ textTitleDictionary[publishLogItem.networkId] }}
                </div>
                <div class="cell">URL</div>
                <div class="cell">Status</div>
                <div class="cell">Published by</div>
            </div>

            <div class="body row">
                <div class="cell" *ngIf="publishLogItem?.adName; else empty">
                    {{ publishLogItem.adName }}
                </div>
                <div class="cell" *ngIf="publishLogItem?.adFormat?.name; else empty">
                    {{ publishLogItem.adFormat.name }}
                </div>
                <div class="cell" *ngIf="publishLogItem?.languageId; else empty">
                    <language-item [languageId]="publishLogItem.languageId">
                    </language-item>
                </div>
                <div class="cell preview" *ngIf="publishLogItem.texts?.[0]; else empty">
                    <div class="text-ellipsis">{{ publishLogItem.texts[0] }}</div>
                    <preview-primary-texts
                        *ngIf="publishLogItem.texts.length > 1"
                        [primaryTexts]="publishLogItem.texts">
                    </preview-primary-texts>
                </div>
                <div class="cell" *ngIf="publishLogItem.url; else empty">
                    {{ publishLogItem.url }}
                </div>
                <div class="cell" *ngIf="publishLogItem?.status; else empty">
                    {{ publishLogItem.status }}
                </div>
                <div class="cell" *ngIf="publishLogItem?.publishedBy; else empty">
                    {{ publishLogItem.publishedBy }}
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #empty>
    <div class="cell empty">{{ "Shared.FALLBACK_EMPTY" | text }}</div>
</ng-template>
