import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';

@Component({
	selector: 'fb-audience-header',
	templateUrl: './fb-audience-header.component.html',
	styleUrls: ['./fb-audience-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule, BrandLogoComponent],
})
export class FbAudienceHeaderComponent {
	@Input({ required: true }) public primaryTextValue: string;

	public brand$: Observable<IBrandVM> =
		this.appFeatureServices.accountFeature.brand.load();
	constructor(private appFeatureServices: AppFeatureServices) {}
}
