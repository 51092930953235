import { Injectable } from '@angular/core';
import {
	IDetailedAdVM,
	IFacebookAdVM,
	IFacebookPlacementVM,
	IPublishLogItemVM,
} from 'src/app/presentation/view-models';
import { AdContentIdEnum } from '../single/placements/shared/enums';

@Injectable({
	providedIn: 'root',
})
export class PlacementTargetUrlService {
	public getTargetUrlFromPublishLogItem(
		placementId: string,
		publishLogItem: IPublishLogItemVM,
	): string | undefined {
		return publishLogItem.placements.find(({ id }) => id === placementId)
			?.content?.[AdContentIdEnum.websiteUrl];
	}

	public getTargetUrlFromAd(
		placementId: string,
		ad: IFacebookAdVM,
	): string | undefined {
		if (!ad?.content) {
			return;
		}
		const placement: IFacebookPlacementVM = ad?.content[placementId];

		if (!placement) {
			return;
		}

		const customizedUrl =
			placement?.customizedContent?.content[AdContentIdEnum.websiteUrl];

		if (customizedUrl) {
			return customizedUrl;
		}

		if (ad?.defaultContent[AdContentIdEnum.appUrl]) {
			return ad?.defaultContent[AdContentIdEnum.appUrl];
		}

		if (placement.adContentTemplates) {
			return placement.adContentTemplates.find(
				(template) => template.id === AdContentIdEnum.websiteUrl,
			)?.value;
		}

		return ad.defaultContent?.[AdContentIdEnum.websiteUrl];
	}

	public getTargetUrlFromDetailedAd(
		placementId: string,
		detailedAd: IDetailedAdVM,
	): string | undefined {
		if (!detailedAd?.defaultContent) {
			return;
		}
		const adPlacement = detailedAd.placements.find(
			(placementBase) => placementBase.placementId === placementId,
		);

		if (!adPlacement) {
			return;
		}

		if (adPlacement.isCustomized) {
			const customizedUrl =
				adPlacement?.content[AdContentIdEnum.websiteUrl];

			if (customizedUrl) {
				return customizedUrl;
			}
		}

		if (detailedAd.defaultContent[AdContentIdEnum.appUrl]) {
			return detailedAd.defaultContent[AdContentIdEnum.appUrl];
		}

		return detailedAd.defaultContent?.[AdContentIdEnum.websiteUrl];
	}
}
