import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { LinkedInPlacementBaseDirective } from '../directives/in-placement-base.directive';
import { LinkedInSinglePlacementsComponentStore } from '../in-single-placements.component.store';
import { LinkedInSupportedPlacements } from '../../enums/in-supported-placement.enum';
import { CreativeContentComponent } from '../../../../elements/creative-content/creative-content.component';
import { LinkedInBrandHeaderComponent } from '../elements/brand-header/in-brand-header.component';
import { LinkedInIntroductoryTextComponent } from '../elements/introductory-text/in-introductory-text.component';
import { LinkedInCtaButtonComponent } from '../elements/cta-button/in-cta-button.component';
import { LinkedInHeadlineComponent } from '../elements/headline/in-headline.component';

@Component({
	selector: 'in-linkedin-audience-network',
	templateUrl: './in-linkedin-audience-network.component.html',
	styleUrls: ['./in-linkedin-audience-network.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		LinkedInBrandHeaderComponent,
		UIModule,
		LinkedInIntroductoryTextComponent,
		LinkedInCtaButtonComponent,
		LinkedInHeadlineComponent,
	],
})
export class LinkedInAudienceNetworkComponent extends LinkedInPlacementBaseDirective {
	constructor(
		linkedInSinglePlacementsComponentStore: LinkedInSinglePlacementsComponentStore,
	) {
		super(
			linkedInSinglePlacementsComponentStore,
			LinkedInSupportedPlacements.LinkedInAudienceNetwork,
		);
	}
}
