import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { filter, map, Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Component({
	selector: 'instagram-reels-cta',
	templateUrl: './instagram-reels-cta.component.html',
	styleUrls: ['./instagram-reels-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class InstagramReelsCtaComponent implements OnChanges {
	@Input() public ctaValue = '';
	public ctaName$: Observable<string>;

	constructor(private appFeatureService: AppFeatureServices) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.ctaValue) {
			this.ctaName$ = this.appFeatureService.facebookFeature.account.cta
				.loadAllCtas()
				.pipe(
					filter((ctas) => !!ctas),
					map(
						(ctas) =>
							ctas.find((cta) => cta.id === this.ctaValue)?.name,
					),
				);
		}
	}
}
