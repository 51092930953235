import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TruncatePipe } from 'src/app/presentation/pages/shared/pipes/truncate.pipe';

@Component({
	selector: 'in-headline',
	templateUrl: './in-headline.component.html',
	styleUrls: ['./in-headline.component.scss'],
	standalone: true,
	imports: [CommonModule, TruncatePipe],
})
export class LinkedInHeadlineComponent {
	@Input({ required: true }) public headlineValue: string;
}
