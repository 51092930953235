import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { IPlacementInteraction } from 'src/app/presentation/features/ad/ad-editor/shared/placement-interaction/placement-interaction';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	standalone: true,
	imports: [TextPipe, UIModule, CommonModule],
	selector: 'facebook-placement-footer',
	templateUrl: './fb-placement-footer.component.html',
	styleUrls: ['./fb-placement-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPlacementFooterComponent {
	@Input() public interactions?: IPlacementInteraction[] = [
		{ name: 'Like', icon: 'like-outline' },
		{ name: 'Comment', icon: 'comments' },
		{ name: 'Share', icon: 'share' },
	];
}
