import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CreativeContentComponent } from '../../../../../../elements/creative-content/creative-content.component';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { CarouselDotsComponent } from '../../carousel-elements/carousel-dots/carousel-dots.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { SliderTypeEnum } from '../../carousel-elements/carousel-slider/slider-type';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { FbInstagramFeedCtaComponent } from '../fb-feed-elements/instagram-feed-cta/instagram-feed-cta.component';
import { FbSocialBarComponent } from '../fb-feed-elements/instagram-feed-social-bar/instagram-feed-social-bar.component';

@Component({
	selector: 'fb-carousel-instagram-profile-feed-placement',
	templateUrl: './fb-instagram-profile-feed.component.html',
	styleUrls: ['./fb-instagram-profile-feed.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		CarouselSliderComponent,
		FbPlacementHeaderComponent,
		FbPrimaryTextComponent,
		CreativeContentComponent,
		FbSocialBarComponent,
		FbInstagramFeedCtaComponent,
		CarouselDotsComponent,
	],
})
export class FacebookCarouselInstagramProfileFeedPlacementComponent extends FacebookCarouselBaseDirective {
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;

	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookInstagramProfileFeed,
		);
	}
}
