import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { FbMenuDotsIconComponent } from '../../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';
import { FbHeadlineComponent } from '../../../../../placement-elements/headline/fb-headline.component';

@Component({
	selector: 'ads-on-fb-reels-card',
	templateUrl: './ads-on-fb-reels-card.component.html',
	styleUrls: ['./ads-on-fb-reels-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		TextPipe,
		FbMenuDotsIconComponent,
		FbHeadlineComponent,
		CreativeContentComponent,
	],
})
export class AdsOnFbReelsCardComponent {
	@Input({ required: true }) public card: IFacebookCarouselCardMetaDataVM;
	@Input({ required: true }) public brandName = '';
}
