import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADGROUP_CONNECTION_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IConnection } from 'src/app/core/models';

@Injectable()
export class AdGroupConnectionDataService extends DefaultDataService<IConnection> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(ADGROUP_CONNECTION_ENTITY, http, httpUrlGenerator);
	}

	public getById(adGroupId: string): Observable<IConnection> {
		return this.appCoreServices.socialCampaignApiService.adGroup.getConnection(
			adGroupId,
		);
	}

	public add(connection: IConnection): Observable<IConnection> {
		return this.appCoreServices.socialCampaignApiService.adGroup.connect(
			connection,
		);
	}

	public delete(adGroupId: string): Observable<any> {
		return this.appCoreServices.socialCampaignApiService.adGroup.deleteConnection(
			adGroupId,
		);
	}
}
