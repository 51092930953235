import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { LinkedInAdAccountBaseService } from './in-ad-account-base.service';
import { ILinkedInAdAccount } from 'src/app/core/models/linkedin/in-ad-account.model';
import { LinkedInAdAccountMapperService } from '../../mappers/in-ad-account.mapper';
import { ILinkedInAdAccountVM } from 'src/app/presentation/view-models/linkedin/account/in-ad-account.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInAdAccountService extends LinkedInAdAccountBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		private adAccountMapper: LinkedInAdAccountMapperService,
	) {
		super(appEntityServices);
	}

	public loadAllByIntegrationId(
		integrationId: string,
	): Observable<ILinkedInAdAccount[]> {
		const adAccounts$: Observable<ILinkedInAdAccountVM[]> =
			this.appEntityServices.linkedInEntity.adAccount.entities$;

		return adAccounts$.pipe(
			filter((accounts) => !!accounts),
			map((accounts) =>
				accounts.filter(
					(account) => account.integrationId === integrationId,
				),
			),
			map((accounts) =>
				accounts.map((account) =>
					this.adAccountMapper.toAdAccountVM(account),
				),
			),
		);
	}

	public isLoadingAdAccounts(): Observable<boolean> {
		return this.appEntityServices.linkedInEntity.adAccount.loading$;
	}
}
