import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	ICreativeSetFolder,
	IClvCreativeSetsResponse,
} from '../../models/creative/core-creative-set/ex-creativeset';

import { CommonListViewReponseMapperService } from './response-mapper/clv-response.mapper.service';

@Injectable({
	providedIn: 'root',
})
export class CommonListViewApiService {
	public baseUrl = `${ConfigService?.config?.CLV_URL}/api`;

	constructor(
		private http: HttpClient,
		private commonListViewResponseMapperService: CommonListViewReponseMapperService,
	) {}

	/**
	 * Gets brand creatives from bannerflow api
	 */
	public getCreativeSetsWithFolders(
		brandId: string,
		search?: boolean,
		queryValue?: string,
		folderId?: string,
	): Observable<ICreativeSetFolder[]> {
		const apiUrl = `${this.baseUrl}/${brandId}/creativesets`;

		const query = JSON.stringify(
			this.commonListViewResponseMapperService.getCreativeSetQueryCLV(
				search,
				queryValue,
				folderId,
			),
		);

		const url = query.includes('filters')
			? `${apiUrl}/FilterCreativeSetsAndFolders`
			: `${apiUrl}/GetCreativeSetAndFolders`;

		return this.http
			.post<IClvCreativeSetsResponse>(url, JSON.parse(query))
			.pipe(
				map((clvResponse: IClvCreativeSetsResponse) =>
					this.commonListViewResponseMapperService.deserializeCreativeSetResponse(
						clvResponse,
					),
				),
			);
	}
}
