import { Injectable } from '@angular/core';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { PublishLogMappersService } from '../../../publish/mappers/publish-log.mapper';
import {
	IPublishLogItemVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { TiktokAdContentIdEnum } from 'src/app/presentation/features/integrations/tiktok/ad/enums/tt-ad-content-id.enum';
import { IAdContentDictionary } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class TiktokPublishLogMappersService extends PublishLogMappersService {
	constructor(private textPipe: TextPipe) {
		super();
	}

	public toPublishLogItemViewModel(
		publishLog: IPublishLogEntry,
	): IPublishLogItemVM {
		if (!publishLog?.ad) {
			return this.toPublishLogItemViewModelWithEmptyAd(publishLog);
		}
		const adContent = this.extractAdContent(publishLog?.ad);

		return {
			id: publishLog.id,
			adId: publishLog.adId,
			networkId: NetworkEnum.tiktok,
			adFormat: this.toAdFormatVM(publishLog.ad?.format),
			adName: publishLog.ad?.name,
			date: publishLog.publishingStarted,
			languageId: publishLog.ad?.language,
			publishedBy: publishLog.publishedBy,
			status: publishLog.status,
			statusMessage: publishLog.statusMessage,
			creativeAssigned: publishLog.ad?.assignedCreativeSet,
			texts: this.mapTexts(adContent),
			url: this.mapUrl(adContent),
			placements: publishLog.ad
				? this.toPlacementsVM(publishLog.ad?.placements)
				: [],
		};
	}

	private mapTexts(content: IAdContentDictionary): string[] | undefined {
		if (!content) {
			return undefined;
		}

		const text = content[TiktokAdContentIdEnum.text];

		return text ? [text] : undefined;
	}

	private mapUrl(content: IAdContentDictionary): string | undefined {
		if (!content) {
			return undefined;
		}

		if (content[TiktokAdContentIdEnum.tiktokInstantPage]) {
			return this.textPipe.transform('Shared.INSTANT_PAGE_WAS_USED');
		}

		return content[TiktokAdContentIdEnum.targetUrl];
	}
}
