import { Component } from '@angular/core';

import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { CommonModule } from '@angular/common';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';
import { FacebookPlacementHeaderComponent } from '../../../shared/placement-header/fb-placement-header.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		HeadlineComponent,
		FacebookPlacementHeaderComponent,
	],
	selector: 'facebook-marketplace-feed-placement-core',
	templateUrl: './fb-marketplace-feed-placement-core.component.html',
	styleUrls: ['./fb-marketplace-feed-placement-core.component.scss'],
})
export class FacebooMarketplaceFeedPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookMarketPlace,
		);
	}
}
