import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { TiktokAdFormatDataService } from './ad-format/tt-ad-format-data.service';
import { TiktokAdFormatsEntityService } from './ad-format/tt-ad-formats-entity.service';
import { EntityDataService } from '@ngrx/data';
import {
	TIKTOK_AD_ACCOUNT_ENTITY,
	TIKTOK_AD_FORMAT_ENTITY,
	TIKTOK_AD_GROUP_ENTITY,
	TIKTOK_AD_GROUP_PLACEMENT_ENTITY,
	TIKTOK_CAMPAIGN_ENTITY,
	TIKTOK_CTA_ENTITY,
	TIKTOK_IDENTITY_ENTITY,
	TIKTOK_SINGLE_IMAGE_PLACEMENT_ENTITY,
	TIKTOK_SINGLE_VIDEO_PLACEMENT_ENTITY,
} from 'src/app/state/app.state';
import { TiktokAdGroupDataService } from './ad-group/tt-ad-group-data.service';
import { TiktokAdGroupPlacementDataService } from './ad-group-placement/tt-ad-group-placement-data.service';
import { TiktokCampaignDataService } from './campaign/tt-campaign-data.service';
import { TiktokIdentityDataService } from './identity/tt-identity-data.service';
import { TiktokAdGroupsEntityService } from './ad-group/tt-ad-group-entity.service';
import { TiktokAdGroupPlacementsEntityService } from './ad-group-placement/tt-ad-group-placement-entity.service';
import { TiktokCampaignEntityService } from './campaign/tt-campaign-entity.service';
import { TiktokIdentityEntityService } from './identity/tt-identity-entity.service';
import { TiktokAdAccountDataService } from './ad-account/tt-ad-account-data.service';
import { TiktokAdAccountsEntityService } from './ad-account/tt-ad-accounts-entity.service';
import { TiktokSingleImagePlacementDataService } from './single-image-placement/tt-single-image-placement-data.service';
import { TiktokSingleImagePlacementsEntityService } from './single-image-placement/tt-single-image-placements-entity.service';
import { TiktokSingleVideoPlacementDataService } from './single-video-placement/tt-single-video-placement-data.service';
import { TiktokSingleVideoPlacementsEntityService } from './single-video-placement/tt-single-video-placements-entity.service';
import { TiktokCtaDataService } from './cta/tt-cta-data.service';
import { TiktokCtaEntityService } from './cta/tt-cta-entity.service';
@NgModule({
	declarations: [],
	imports: [],
	providers: [
		TiktokAdFormatsEntityService,
		TiktokAdFormatDataService,
		TiktokAdAccountDataService,
		TiktokAdAccountsEntityService,
		TiktokAdGroupDataService,
		TiktokAdGroupsEntityService,
		TiktokAdGroupPlacementDataService,
		TiktokAdGroupPlacementsEntityService,
		TiktokCampaignDataService,
		TiktokCampaignEntityService,
		TiktokIdentityDataService,
		TiktokIdentityEntityService,
		TiktokSingleImagePlacementDataService,
		TiktokSingleImagePlacementsEntityService,
		TiktokSingleVideoPlacementDataService,
		TiktokSingleVideoPlacementsEntityService,
		TiktokCtaDataService,
		TiktokCtaEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityTiktokModule {
	private tiktokAdAccountDataService: TiktokAdAccountDataService;
	private tiktokAdFormatDataService: TiktokAdFormatDataService;
	private tiktokAdGroupDataService: TiktokAdGroupDataService;
	private tiktokAdGroupPlacementDataService: TiktokAdGroupPlacementDataService;
	private tiktokCampaignDataService: TiktokCampaignDataService;
	private tiktokIdentityDataService: TiktokIdentityDataService;
	private tiktokSingleImagePlacementDataService: TiktokSingleImagePlacementDataService;
	private tiktokSingleVideoPlacementDataService: TiktokSingleVideoPlacementDataService;
	private tiktokCtaDataService: TiktokCtaDataService;

	constructor(
		entityDataService: EntityDataService,
		private injector: Injector,
	) {
		this.tiktokAdAccountDataService = this.injector.get(
			TiktokAdAccountDataService,
		);

		entityDataService.registerService(
			TIKTOK_AD_ACCOUNT_ENTITY,
			this.tiktokAdAccountDataService,
		);

		this.tiktokAdFormatDataService = this.injector.get(
			TiktokAdFormatDataService,
		);

		entityDataService.registerService(
			TIKTOK_AD_FORMAT_ENTITY,
			this.tiktokAdFormatDataService,
		);

		this.tiktokAdGroupDataService = this.injector.get(
			TiktokAdGroupDataService,
		);

		entityDataService.registerService(
			TIKTOK_AD_GROUP_ENTITY,
			this.tiktokAdGroupDataService,
		);

		this.tiktokAdGroupPlacementDataService = this.injector.get(
			TiktokAdGroupPlacementDataService,
		);

		this.tiktokCtaDataService = this.injector.get(TiktokCtaDataService);

		entityDataService.registerService(
			TIKTOK_AD_GROUP_PLACEMENT_ENTITY,
			this.tiktokAdGroupPlacementDataService,
		);

		this.tiktokCampaignDataService = this.injector.get(
			TiktokCampaignDataService,
		);

		entityDataService.registerService(
			TIKTOK_CAMPAIGN_ENTITY,
			this.tiktokCampaignDataService,
		);

		this.tiktokIdentityDataService = this.injector.get(
			TiktokIdentityDataService,
		);

		this.tiktokSingleImagePlacementDataService = this.injector.get(
			TiktokSingleImagePlacementDataService,
		);

		this.tiktokSingleVideoPlacementDataService = this.injector.get(
			TiktokSingleVideoPlacementDataService,
		);

		entityDataService.registerService(
			TIKTOK_IDENTITY_ENTITY,
			this.tiktokIdentityDataService,
		);

		entityDataService.registerService(
			TIKTOK_SINGLE_IMAGE_PLACEMENT_ENTITY,
			this.tiktokSingleImagePlacementDataService,
		);

		entityDataService.registerService(
			TIKTOK_SINGLE_VIDEO_PLACEMENT_ENTITY,
			this.tiktokSingleVideoPlacementDataService,
		);

		entityDataService.registerService(
			TIKTOK_CTA_ENTITY,
			this.tiktokCtaDataService,
		);
	}
}
