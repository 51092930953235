import { CommonModule } from '@angular/common';
import {
	Component,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { IUserVM, RouteView } from 'src/app/presentation/view-models';
import { CampaignHeaderComponent } from '../../campaign/campaign-header/campaign-header.component';
import { EnvironmentPickerComponent } from '../../account/environment-picker/environment-picker.component';
import { NSNotificationsComponent } from '@bannerflow/notification';
import { FFFeatureFlagsDirective } from '@bannerflow/feature-flags';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [
		UIModule,
		CommonModule,
		CampaignHeaderComponent,
		TextPipe,
		EnvironmentPickerComponent,
		NSNotificationsComponent,
		FFFeatureFlagsDirective,
	],
	standalone: true,
})
export class HeaderComponent implements OnChanges, OnInit, OnChanges {
	@HostBinding('class') public hostClass: string;

	public user$: Observable<IUserVM | null>;

	@Input() public routeView: string;
	@Input() public showPublishButtons = true;
	@Input() public showAdViews = true;
	@Input() public showCampaignHeader = false;

	public readonly viewOptions: any[] = [
		{
			id: 'ad-list-group-item',
			text: this.textPipe.transform('HeaderComponent.AD_LIST'),
			value: './list',
		},
		{
			id: 'ad-preview-group-item',
			text: this.textPipe.transform('HeaderComponent.AD_PREVIEW'),
			value: './preview',
		},
	];
	public selectedView = './list';

	constructor(
		private appFeatureServices: AppFeatureServices,
		private route: ActivatedRoute,
		private router: Router,
		private textPipe: TextPipe,
	) {}

	public ngOnInit(): void {
		this.user$ = this.appFeatureServices.accountFeature.user.load();
	}

	public ngOnChanges(): void {
		this.updateHostClass();

		if (this.routeView === RouteView.AdList) {
			this.selectedView = './list';
		}

		if (this.routeView === RouteView.AdPreview) {
			this.selectedView = './preview';
		}
	}

	public onViewChanged(viewName: string): void {
		if (viewName === this.selectedView) {
			return;
		} else {
			this.selectedView = viewName;
			this.router.navigate([viewName], { relativeTo: this.route });
		}
	}

	public exitCampaign(user: IUserVM): void {
		window.location.href = `${ConfigService.config.B2_URL}/v2/${user.accountSlug}/${user.brandSlug}/campaigns`;
	}

	private updateHostClass(): void {
		const columnCount =
			this.showPublishButtons && this.showAdViews
				? 'three-columns'
				: 'two-columns';
		this.hostClass = columnCount;
	}
}
