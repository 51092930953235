import { Injectable } from '@angular/core';
import { IAdFormat } from 'src/app/core/models';
import { ILinkedInAdFormatVM } from 'src/app/presentation/view-models/linkedin/in-ad-format.vm';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsMappersService {
	public mapAdFormat(adFormat: IAdFormat): ILinkedInAdFormatVM {
		return {
			id: adFormat.id,
			name: adFormat.name,
		};
	}
}
