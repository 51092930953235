import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ILinkedInCampaign } from 'src/app/core/models/linkedin/in-campaign.model';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { LinkedInCampaignMapperService } from '../../mappers/in-campaign.mapper.ts';
import { ILinkedInCampaignVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign.vm.js';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignBaseService extends BaseFeatureService<ILinkedInCampaignVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public campaignMapper: LinkedInCampaignMapperService,
	) {
		super();
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		campaignGroupId: string;
	}): void {
		this.appEntityServices.linkedInEntity.campaign.getWithQuery(params);
	}

	public loadById(id: string): Observable<ILinkedInCampaignVM> {
		const campaigns$: Observable<ILinkedInCampaign[]> =
			this.appEntityServices.linkedInEntity.campaign.entities$;

		return campaigns$.pipe(
			filter((campaigns) => !!campaigns),
			map((campaigns) =>
				campaigns.find((campaign) => campaign.id === id),
			),
			filter((campaign) => !!campaign),
			map((campaign) => this.campaignMapper.toCampaignVM(campaign)),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ILinkedInCampaignVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<ILinkedInCampaignVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ILinkedInCampaignVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
