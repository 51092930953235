import { Injectable } from '@angular/core';
import {
	IAd,
	IAdContentDictionary,
	IAssignedCreative,
} from 'src/app/core/models';
import { IAdDefault } from 'src/app/core/models/ad/ad/ad-default.model';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { ICarouselCard } from 'src/app/core/models/ad/ad/carousel-card.model';
import { CommonFeatureService } from 'src/app/features/common/common-feature.service';
import { AdFormatEnum } from 'src/app/presentation/view-models';
import {
	IMixedAdDefaultContentVM,
	IMixedCardVM,
	IMixedContentVM,
} from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';

interface MixedData<T, Y> {
	data: T;
	mixed: Y;
}

@Injectable({
	providedIn: 'root',
})
export class BulkAdMapperService {
	constructor(private commonFeature: CommonFeatureService) {}

	public toCombinedAd(ads: IAd[], minCardsAmount?: number): IAd {
		const isFormatCarousel = this.isFormatCarousel(ads);

		const combinedContent: IAdContentDictionary = this.combineValues(
			ads.map((ad) => ad.default.content),
		);
		const combinedCreatives = isFormatCarousel
			? []
			: this.combineCreatives(ads.map((ad) => ad.default.creatives));
		const combinedCards = isFormatCarousel
			? this.combineCards(
					ads.map((ad) => ad.default.cards),
					minCardsAmount,
				)
			: null;

		const adDefaultContent: IAdDefault = {
			content: combinedContent,
			creatives: combinedCreatives,
			cards: combinedCards,
		};

		const placements: IAdPlacement[] = this.combinePlacements(
			ads.map((ad) => ad.placements),
			adDefaultContent,
		);

		const combinedAd: IAd = {
			id: undefined,
			name: undefined,
			adGroupId: undefined,
			languageId: ads[0].languageId,
			adFormatId: ads[0].adFormatId,
			default: adDefaultContent,
			placements,
		};

		return combinedAd;
	}

	public mapMixedAdDefaultContent(
		ads: IAd[],
		minCardsAmount?: number,
	): IMixedAdDefaultContentVM {
		const mixedContent: IAdContentDictionary = this.getMixedContent(
			ads.map((ad) => ad.default.content),
		);
		const mixedCreatives: boolean = this.getMixedCreatives(
			ads.map((ad) => ad.default.creatives),
		);
		const mixedCards = this.isFormatCarousel(ads)
			? this.getMixedCards(
					ads.map((ad) => ad.default.cards),
					minCardsAmount,
				)
			: null;

		return {
			content: mixedContent,
			creatives: mixedCreatives,
			cards: mixedCards,
		};
	}

	private combineValues(values: any[], fallback?: any): any {
		const baseValue = values?.find((value) => typeof value !== 'undefined');

		if (typeof baseValue === 'string') {
			if (values.every((value) => value === baseValue)) {
				return baseValue;
			}

			return fallback;
		} else if (Array.isArray(baseValue)) {
			return this.combineArrays(values);
		} else if (typeof baseValue === 'object') {
			return this.combineObjects(values);
		}

		return undefined;
	}

	private combineArrays(arrays: string[][]): string[] {
		for (let i = 1; i < arrays.length; i++) {
			if (!this.commonFeature.utilities.isEqual(arrays[0], arrays[i])) {
				return [''];
			}
		}

		return arrays[0];
	}

	private combineObjects(
		objects: IAdContentDictionary[],
	): IAdContentDictionary {
		const combinedObject: IAdContentDictionary = {};

		const uniqueIds = [
			...new Set(objects.flatMap((content) => Object.keys(content))),
		];

		uniqueIds.forEach((id) => {
			const values = objects.map((content) => content[id]);

			combinedObject[id] = this.combineValues(values, '');
		});

		return combinedObject;
	}

	private getMixedContent(contents: IAdContentDictionary[]): IMixedContentVM {
		const combinedObject: IMixedContentVM = {};

		const uniqueIds = [
			...new Set(contents.flatMap((content) => Object.keys(content))),
		];

		uniqueIds.forEach((id) => {
			const values = contents.map((content) => content[id]);
			const baseValue = values?.find(
				(value) => typeof value !== 'undefined',
			);

			if (typeof baseValue === 'string') {
				const uniqueValues = Array.from(new Set(values));
				const hasEmptyValues =
					uniqueValues.length === 2 &&
					uniqueValues.includes('') &&
					uniqueValues.includes(undefined);

				if (!hasEmptyValues && uniqueValues.length > 1) {
					combinedObject[id] = true;
				}
			} else if (Array.isArray(baseValue)) {
				const isFirstValueEmpty = this.isArrayEmpty(baseValue);

				for (let i = 1; i < values.length; i++) {
					const isValueEmpty =
						isFirstValueEmpty && this.isArrayEmpty(values[i]);

					const isValueEqual = this.commonFeature.utilities.isEqual(
						values[0],
						values[i],
					);

					if (!isValueEmpty && !isValueEqual) {
						combinedObject[id] = true;
					}
				}
			}
		});

		return combinedObject;
	}

	private isArrayEmpty(arr: string[] | undefined): boolean {
		return (
			(arr?.length === 1 && arr[0] === '') || typeof arr === 'undefined'
		);
	}

	private combineCards(
		cardsArrays: ICarouselCard[][],
		minCardsAmount: number,
	): ICarouselCard[] {
		return this.processCards(cardsArrays, minCardsAmount).data;
	}

	private getMixedCards(
		cards: ICarouselCard[][],
		minCardsAmount: number,
	): IMixedCardVM[] {
		return this.processCards(cards, minCardsAmount).mixed;
	}

	private processCards(
		cardsArray: ICarouselCard[][],
		minCardsAmount: number,
	): MixedData<ICarouselCard[], IMixedCardVM[]> {
		const combinedCards: ICarouselCard[] = [];
		const mixedCards: IMixedCardVM[] = [];

		let pointer = 0;

		cardsArray.forEach((cards) => {
			cards.forEach((_card, index) => {
				if (index < pointer) {
					return;
				}

				pointer = index + 1;

				const cardsOfIndex = cardsArray
					.map((arr) => {
						const card = arr[index];

						if (!card && index < minCardsAmount) {
							return {
								order: index,
								content: {},
								creatives: [],
							};
						}

						return card;
					})
					.filter((item) => !!item);

				const combinedCard: ICarouselCard = {
					order: index,
					content: this.combineValues(
						cardsOfIndex.map((card) => card.content),
					),
					creatives: this.combineCreatives(
						cardsOfIndex.map((card) => card.creatives),
					),
				};

				const mixedCard: IMixedCardVM = {
					numberOfMixedAds: cardsOfIndex.length,
					creatives: this.getMixedCreatives(
						cardsOfIndex.map((card) => card.creatives),
					),
					content: this.getMixedContent(
						cardsOfIndex.map((card) => card.content),
					),
				};

				combinedCards.push(combinedCard);
				mixedCards.push(mixedCard);
			});
		});

		return {
			data: combinedCards,
			mixed: mixedCards,
		};
	}

	private combineCreatives(
		creatives: IAssignedCreative[][],
	): IAssignedCreative[] {
		return this.processCreatives(creatives).data;
	}

	private getMixedCreatives(creatives: IAssignedCreative[][]): boolean {
		return this.processCreatives(creatives).mixed;
	}

	private processCreatives(
		creativesArray: IAssignedCreative[][],
	): MixedData<IAssignedCreative[], boolean> {
		if (creativesArray.every((arr) => arr.length === 0)) {
			return { data: [], mixed: false };
		}

		if (creativesArray.some((arr) => arr.length === 0)) {
			return { data: [], mixed: true };
		}

		const creativeMap = new Map<string, IAssignedCreative>();

		const longestArray = creativesArray.reduce(
			(resultArray, currentArray) =>
				currentArray.length > resultArray.length
					? currentArray
					: resultArray,
			[],
		);

		const createKey = (creative: IAssignedCreative): string =>
			`${creative.setId}-${creative.id}-${creative.renderingOption}`;

		longestArray.forEach((creative) => {
			creativeMap.set(createKey(creative), creative);
		});

		let areCreativesMatching = true;

		creativesArray
			.flatMap((arr) => arr)
			.forEach((creative) => {
				const key = createKey(creative);

				if (creativeMap.has(key)) {
					const value = creativeMap.get(key);

					creativeMap.set(key, {
						...value,
						placementsIds: Array.from(
							new Set([
								...creative.placementsIds,
								...value.placementsIds,
							]),
						),
					});
				} else {
					areCreativesMatching = false;
				}
			});

		return areCreativesMatching
			? { data: Array.from(creativeMap.values()), mixed: false }
			: { data: [], mixed: true };
	}

	private combinePlacements(
		adPlacements: IAdPlacement[][],
		adDefaultContent: IAdDefault,
	): IAdPlacement[] {
		const placementsDictionary: { [key: string]: IAdPlacement } = {};

		adPlacements.forEach((adPlacementArray) => {
			adPlacementArray.forEach((adPlacement) => {
				this.processPlacement(
					adPlacement,
					adDefaultContent,
					placementsDictionary,
				);
			});
		});

		const combinedPlacements = Object.values(placementsDictionary);

		combinedPlacements.forEach((placement) => {
			if (placement.isCustomized) {
				placement.creative = null;
				placement.isCustomized = false;
			}
		});

		return combinedPlacements;
	}

	private processPlacement(
		placement: IAdPlacement,
		adDefaultContent: IAdDefault,
		placementsDictionary: { [key: string]: IAdPlacement },
	): any {
		const placementContent = {};

		Object.keys(placement.content).forEach((key) => {
			placementContent[key] = adDefaultContent.content[key];
		});

		const placementCreative = this.combinePlacementCreative(
			placement.placementId,
			placement.creative,
			adDefaultContent.creatives,
		);
		const placementCards = this.combinePlacementCards(
			placement,
			adDefaultContent.cards,
		);

		let newPlacement: IAdPlacement = {
			...placement,
			content: placementContent,
			creative: placementCreative,
			cards: placementCards,
		};

		const existingPlacement = placementsDictionary[placement.placementId];

		if (existingPlacement && !existingPlacement.isCustomized) {
			if (!placement.isCustomized) {
				const isEqual = this.commonFeature.utilities.isEqual(
					existingPlacement.creative,
					placement.creative,
				);

				if (!isEqual) {
					newPlacement.creative = null;
				}
			} else {
				newPlacement = { ...existingPlacement };
			}
		}

		placementsDictionary[placement.placementId] = newPlacement;
	}

	private combinePlacementCreative(
		placementId: string,
		placementCreative: IAssignedCreative,
		adCreatives: IAssignedCreative[],
	): IAssignedCreative {
		const hasCreative =
			placementCreative &&
			adCreatives?.some(
				(adCreative) =>
					this.isSameCreative(placementCreative, adCreative) &&
					adCreative.placementsIds.includes(placementId),
			);

		return hasCreative ? placementCreative : null;
	}

	private combinePlacementCards(
		placement: IAdPlacement,
		adCards: ICarouselCard[],
	): ICarouselCard[] {
		if (!adCards) {
			return null;
		}

		return adCards.map((adCard, index) => {
			let newCreative: IAssignedCreative;
			const placementCard = placement.cards[index];

			if (!placementCard) {
				newCreative = adCard.creatives.find((c) =>
					c.placementsIds.includes(placement.placementId),
				);
			} else {
				newCreative = this.combinePlacementCreative(
					placement.placementId,
					placementCard.creative,
					adCard.creatives,
				);
			}

			const newCard = {
				...adCard,
				creative: newCreative,
			};

			return newCard;
		});
	}

	private isSameCreative(
		a: IAssignedCreative,
		b: IAssignedCreative,
	): boolean {
		return (
			a.setId === b.setId &&
			a.id === b.id &&
			a.renderingOption === b.renderingOption
		);
	}

	private isFormatCarousel(ads: IAd[]): boolean {
		return ads[0].adFormatId === AdFormatEnum.carousel;
	}
}
