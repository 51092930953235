import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FacebookPlacementFooterComponent } from '../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { FbFeedCardComponent } from '../fb-feed-elements/feed-card/fb-feed-card.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { SliderTypeEnum } from '../../carousel-elements/carousel-slider/slider-type';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { IPlacementInteraction } from 'src/app/presentation/features/ad/ad-editor/shared/placement-interaction/placement-interaction';

@Component({
	selector: 'fb-carousel-video-feed',
	templateUrl: './fb-video-feed.component.html',
	styleUrls: ['./fb-video-feed.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		FbPlacementHeaderComponent,
		FbPrimaryTextComponent,
		CarouselSliderComponent,
		FbFeedCardComponent,
		FacebookPlacementFooterComponent,
	],
})
export class FacebookCarouselVideoFeedComponent extends FacebookCarouselBaseDirective {
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
	public videoFeedFooterInteractions: IPlacementInteraction[] = [
		{ name: 'Share', icon: 'share' },
	];
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookVideoFeed,
		);
	}
}
