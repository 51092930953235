import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { map, Observable } from 'rxjs';
import {
	AdFormatEnum,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { LinkedInSupportedPlacements } from '../enums/in-supported-placement.enum';

@Component({
	selector: 'in-placement-title',
	templateUrl: './in-placement-title.component.html',
	styleUrls: ['./in-placement-title.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedInPlacementTitleComponent implements OnInit {
	@Input() public placementId: string;
	@Input() public adFormatId: string;

	public placement$: Observable<IPlacementOptionsVM>;
	public placementEnum: typeof LinkedInSupportedPlacements =
		LinkedInSupportedPlacements;

	constructor(private appFeatureServices: AppFeatureServices) {}

	public ngOnInit(): void {
		const singleVideoPlacements$ =
			this.appFeatureServices.linkedInFeature.placement.singleVideo.loadPlacements();
		const singleImagePlacements$ =
			this.appFeatureServices.linkedInFeature.placement.singleImage.loadPlacements();

		this.placement$ = (
			this.adFormatId === AdFormatEnum.singleImage
				? singleImagePlacements$
				: singleVideoPlacements$
		).pipe(
			map((placements) =>
				placements.find(
					(placement) => placement.id === this.placementId,
				),
			),
		);
	}
}
