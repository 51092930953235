<ng-container>
    <div class="left">
        <div class="exit-publish">
            <ui-svg-icon icon="close" class="close" (click)="resetSelectedAds()" />
            <div class="selected-ads" *ngIf="(numberOfSelectedAds$ | async) as adsCount">
                {{adsCount}} {{(adsCount === 1)? 'ad': 'ads'}} {{"Shared.SELECTED" |
                text}}
            </div>
        </div>
    </div>

    <div class="right" *ngIf="(selectedAds$ | async) as selectedAds">
        <ui-button
            *ngIf="(isAdsValidForPublish(selectedAds) | async) as validationMessages"
            id="interaction-publish-button"
            data-cy="publish-button-active"
            class="action-button"
            [text]="('Shared.PUBLISH_ACTIVE'| text) + ' (' + (numberOfSelectedAds$ | async) + ')' "
            type="default"
            icon="upload"
            [uiTooltip]="'Shared.PUBLISH_SUMMARY_ACTIVE' | text"
            uiTooltipTrigger="hover"
            uiTooltipPosition="bottom"
            (click)=" publish(validationMessages, publishAsEnum.active)">
        </ui-button>

        <ui-button
            *ngIf="(isAdsValidForPublish(selectedAds) | async) as validationMessages"
            id="interaction-publish-button"
            data-cy="publish-button-pause"
            class="action-button"
            [text]="('Shared.PUBLISH_PAUSED'| text) + ' (' + (numberOfSelectedAds$ | async) + ')' "
            type="primary"
            icon="upload"
            [uiTooltip]="'Shared.PUBLISH_SUMMARY_PAUSED' | text"
            uiTooltipTrigger="hover"
            uiTooltipPosition="bottom"
            (click)="publish(validationMessages, publishAsEnum.paused)">
        </ui-button>
        <ns-notifications-component *ffFeatureFlag="'NotificationService-Integrations'" />
    </div>
</ng-container>
