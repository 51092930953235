import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { FacebookAdSetApiService } from './services/fb-ad-set-api.service';
import { FacebookCampaignsApiService } from './services/fb-campaigns-api.service';
import { FacebookPageApiService } from './services/fb-pages-api.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookCampaignApiService extends BaseApiService {
	constructor(
		public http: HttpClient,
		public adSetApi: FacebookAdSetApiService,
		public campaignApi: FacebookCampaignsApiService,
		public pageApi: FacebookPageApiService,
	) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/facebook`);
	}
}
