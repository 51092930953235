import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IFacebookPlacementCarouselMetaDataVM } from 'src/app/presentation/view-models';
import { FbHeaderInboxComponent } from '../fb-header-inbox/fb-header-inbox.component';
import { UIModule } from '@bannerflow/ui';
import { CarouselSliderComponent } from '../../../carousel-elements/carousel-slider/carousel-slider.component';
import { SliderTypeEnum } from '../../../carousel-elements/carousel-slider/slider-type';

import { FbPrimaryTextComponent } from '../../../../../placement-elements/primary-text/fb-primary-text.component';
import { CarouselCardInboxComponent } from '../carousel-card-inbox/carousel-card-inbox.component';
import { FbMenuDotsIconComponent } from '../../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FbHeaderInboxComponent,
		UIModule,
		CarouselSliderComponent,
		CarouselCardInboxComponent,
		FbPrimaryTextComponent,
		FbMenuDotsIconComponent,
	],
	selector: 'fb-inbox-expanded',
	templateUrl: './fb-inbox-expanded.component.html',
	styleUrls: ['./fb-inbox-expanded.component.scss'],
})
export class FbInboxExpandedComponent {
	@Input() public placementMetaData: IFacebookPlacementCarouselMetaDataVM;
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
}
