import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
	IAssignedCreativeVM,
	IFacebookPlacementSingleMetaDataVM,
} from 'src/app/presentation/view-models';
import { CustomizePlacementWizardStore } from '../customize-placement.component.store';
import { FacebookSupportedPlacements } from '../../placements/shared/enums';
import { CommonModule } from '@angular/common';
import { FacebookGroupsFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-groups-feed-placement/core/fb-groups-feed-placement-core.component';
import { FacebookInstagramExploreHomePlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-instagram-explore-home-placement/core/fb-instagram-explore-home-placement-core.component';
import { FacebookInstagramExplorePlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-instagram-explore-placement/core/fb-instagram-explore-placement-core.component';
import { FacebookInstagramFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-instagram-feed-placement/core/fb-instagram-feed-placement-core.component';
import { FacebooMarketplaceFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-marketplace-feed-placement/core/fb-marketplace-feed-placement-core.component';
import { FacebookMessangerInboxCoreComponent } from '../../placements/facebook-feed-placements/facebook-messenger-inbox/core/facebook-messanger-inbox-core/fb-messanger-inbox-core.component';
import { FacebookNewsFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-news-feed-placement/core/fb-news-feed-placement-core.component';
import { FacebookRightColumnPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-right-column-placement/core/fb-right-column-placement-core.component';
import { FacebookVideoFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-video-feed-placement/core/fb-video-feed-placement-core.component';
import { FacebookAudienceNetworkNativeCoreComponent } from '../../placements/other-placements/facebook-audience-network-native/core/fb-audience-network-native-core.component';
import { FacebookInStreamVideosCoreComponent } from '../../placements/other-placements/facebook-in-stream-videos/core/fb-in-stream-videos-core.component';
import { FacebookSearchResultPlacementCoreComponent } from '../../placements/other-placements/facebook-search-result/core/fb-search-result-core.component';
import { FacebookAdsOnReelsPlacementCoreComponent } from '../../placements/stories-reels-placements/reels/facebook-ads-on-reels/core/facebook-ads-on-reels-placement-core.component';
import { FacebookInstagramReelsPlacementCoreComponent } from '../../placements/stories-reels-placements/reels/facebook-instagram-reels-placement/core/fb-instagram-reels-placement-core.component';
import { FacebookReelsPlacementCoreComponent } from '../../placements/stories-reels-placements/reels/facebook-reels/core/fb-reels-placement-core.component';
import { FacebookInstagramStoriesPlacementCoreComponent } from '../../placements/stories-reels-placements/stories/facebook-instagram-stories-placement/core/fb-instagram-stories-placement-core.component';
import { FacebookMessengerStoriesPlacementCoreComponent } from '../../placements/stories-reels-placements/stories/facebook-messenger-stories-placement/core/fb-messenger-stories-placement-core.component';
import { FacebookStoriesPlacementCoreComponent } from '../../placements/stories-reels-placements/stories/facebook-stories-placement/core/fb-stories-placement-core.component';
import { FacebookInstagramProfileFeedPlacementCoreComponent } from '../../placements/facebook-feed-placements/facebook-instagram-profile-feed-placement/core/fb-instagram-profile-feed-placement-core.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FacebookNewsFeedPlacementCoreComponent,
		FacebookGroupsFeedPlacementCoreComponent,
		FacebookInstagramFeedPlacementCoreComponent,
		FacebookInstagramProfileFeedPlacementCoreComponent,
		FacebookInstagramExplorePlacementCoreComponent,
		FacebookInstagramExploreHomePlacementCoreComponent,
		FacebooMarketplaceFeedPlacementCoreComponent,
		FacebookRightColumnPlacementCoreComponent,
		FacebookAudienceNetworkNativeCoreComponent,
		FacebookInStreamVideosCoreComponent,
		FacebookVideoFeedPlacementCoreComponent,
		FacebookStoriesPlacementCoreComponent,
		FacebookMessengerStoriesPlacementCoreComponent,
		FacebookInstagramStoriesPlacementCoreComponent,
		FacebookMessangerInboxCoreComponent,
		FacebookReelsPlacementCoreComponent,
		FacebookSearchResultPlacementCoreComponent,
		FacebookInstagramReelsPlacementCoreComponent,
		FacebookAdsOnReelsPlacementCoreComponent,
	],
	selector: 'customize-placement-preview',
	templateUrl: './customize-placement-preview.component.html',
})
export class CustomizePlacementPreviewComponent implements OnInit {
	@Input() public placementId: string;
	public placementMetaData$: Observable<IFacebookPlacementSingleMetaDataVM>;
	public selectedCreative$: Observable<IAssignedCreativeVM>;

	public facebookSupportedPlacements = FacebookSupportedPlacements;
	@Input() public ratioGroup: string;

	constructor(
		private readonly placementWizardStore: CustomizePlacementWizardStore,
	) {}

	public ngOnInit(): void {
		this.selectedCreative$ =
			this.placementWizardStore.loadSelectedCreative();
		this.placementMetaData$ =
			this.placementWizardStore.loadCustomizPlacementContent();
	}
}
